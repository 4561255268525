/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json";
// import config from "react-global-configuration";

// contexts
// import { AuthContext } from "../context/authContext";

// import { useTranslation } from 'react-i18next';
import i18next from "i18next";

import { useState, useEffect } from "react";

import { Img } from "../components";

import { Header, Footer } from "../implementedComponents";

import osterSpecialHeaderImage from "../assets/images/oster-special/Ostern_Website-Header.jpg";

import HubspotForm from "react-hubspot-form";
const generals = require("../generals");

export default function OsterSpecial() {
  // const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);
  }

  useEffect(() => {
    loadLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-16 md:pt-22 pb-0 lg:pb-0">
              <div className="mx-0 px-0">
                <Img
                  className="z-20 w-full h-auto object-cover"
                  src={osterSpecialHeaderImage}
                  alt=""
                />
              </div>
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  {/* <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    {t("Privacy Policy")}
                  </h1> */}

                  {i18next.language === "en" ? (
                    <>
                      <p className="mb-3">
                        Ostern steht vor der Tür und wir haben das perfekte
                        Angebot für dich oder dein Team: Suchst du gerade ein
                        neues Büro für dich und deine Kollegen? Oder hast
                        Interesse an einem langfristigen Arbeitsplatz in einer
                        modern eingerichteten, offenen Bürofläche? Dann miete
                        dir jetzt einen Flex Desk, ein Hybrid Office oder ein
                        Private Office für mindestens 12 Monate und zahle nur 11
                        Monate! Suchst du noch den Haken? Es gibt keinen!
                      </p>
                      <p className="mb-3">
                        Denn unsere Coworking Spaces sind der perfekte Ort für
                        kreative Köpfe und alle, die aus ihrem Homeoffice
                        ausbrechen wollen. Hier findest du nicht nur eine
                        moderne Büroausstattung, sondern eine produktive
                        Arbeitsatmosphäre, nette Kollegen und jede Menge
                        Möglichkeiten zum Netzwerken.
                      </p>
                      <p className="mb-3">
                        Um das Angebot zu erhalten, musst du einfach nur unser
                        Kontaktformular ausfüllen. Aber beeil dich, das Angebot
                        ist nur für begrenzte Zeit gültig. Also schnapp dir dein
                        Osterei und komm vorbei!
                      </p>
                      <p className="italic mb-4">Your orangery Team</p>
                      <p>
                        *Das Angebot ist für Neukunden an folgenden Standorten
                        gültig: Stralsund, Solingen, Lemgo, Ulm, Langen
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="mb-3">
                        Ostern steht vor der Tür und wir haben das perfekte
                        Angebot für dich oder dein Team: Suchst du gerade ein
                        neues Büro für dich und deine Kollegen? Oder hast
                        Interesse an einem langfristigen Arbeitsplatz in einer
                        modern eingerichteten, offenen Bürofläche? Dann miete
                        dir jetzt einen Flex Desk, ein Hybrid Office oder ein
                        Private Office für mindestens 12 Monate und zahle nur 11
                        Monate! Suchst du noch den Haken? Es gibt keinen!
                      </p>
                      <p className="mb-3">
                        Denn unsere Coworking Spaces sind der perfekte Ort für
                        kreative Köpfe und alle, die aus ihrem Homeoffice
                        ausbrechen wollen. Hier findest du nicht nur eine
                        moderne Büroausstattung, sondern eine produktive
                        Arbeitsatmosphäre, nette Kollegen und jede Menge
                        Möglichkeiten zum Netzwerken.
                      </p>
                      <p className="mb-3">
                        Um das Angebot zu erhalten, musst du einfach nur unser
                        Kontaktformular ausfüllen. Aber beeil dich, das Angebot
                        ist nur für begrenzte Zeit gültig. Also schnapp dir dein
                        Osterei und komm vorbei!
                      </p>
                      <p className="italic mb-4">Your orangery Team</p>
                      <p>
                        *Das Angebot ist für Neukunden an folgenden Standorten
                        gültig: Stralsund, Solingen, Lemgo, Ulm, Langen
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </main>
          <script
            charset="utf-8"
            type="text/javascript"
            src="//js.hsforms.net/forms/embed/v2.js"
          ></script>
          <div
            id="openSpecialForm"
            className=" text-center pl-4 md:pl-[200px] mx-auto w-full md:max-w-[800px]"
          >
            <HubspotForm
              region="na1"
              portalId="7914845"
              formId="2ce01a5c-1351-4a5d-91eb-a7418153e961"
              onSubmit={() => {}}
              onReady={(form) => {}}
              loading={<div>Loading...</div>}
            />
          </div>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}
