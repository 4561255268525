import firstNewsImage from "../assets/images/news/News_Banner_new.png";
import image221019 from "../assets/images/news/Halloween_Reminder_update_Website.jpg";
import image221213_2 from "../assets/images/news/News_Banner_new.png";
import image221214 from "../assets/images/news/1649010770666-DSC02585.jpg";
import orangeryHostImage from "../assets/images/news/Orangery_HOST_2023.jpg";

// import { useTranslation } from 'react-i18next';
// const { i18n } = useTranslation();

let newsList = [
  {
    key: "Orangery_HOST_2023",
    de: {
      cleanTitle:
        "Orangery und die Hochschule Stralsund intensivieren ihre Kooperation",
      title: (
        <>
          <span className="text-brand">Orangery</span> und die{" "}
          <span className="text-brand">Hochschule Stralsund</span> intensivieren
          ihre Kooperation
        </>
      ),
      imageSrc: orangeryHostImage,
      date: "Pressemitteilung 31. März 2023",
      summary:
        "Gemeinsame Projekte mit einem Fokus auf Gründen und Startups im Coworking-Space am Stralsunder Hafen sollen Interessen und Communities näher zusammenbringen.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Gemeinsame Projekte mit einem Fokus auf Gründen und Startups im
            Coworking-Space am Stralsunder Hafen sollen Interessen und
            Communities näher zusammenbringen.
          </p>

          <p className="mb-3 text-justify">
            Sich bereichern, austauschen, zusammenarbeiten – die orangery, der
            Coworking-Space am Stralsunder Hafen, und die Hochschule Stralsund
            (HOST) intensivieren ihre Kooperation. Mit der Besetzung des neuen
            Community Managers, Lennart Deorocki, werden gemeinsame Formate
            geplant. „Wir wollen unser Haus mit viel Leben füllen und frischen
            Startups, die finanziell noch nicht oben auf sind, eine Plattform
            und Raum bieten“, erklärt Lennart Deorocki. Konkret bedeutet das,
            dass Studierende der HOST und vorzugsweise studentische Gründerteams
            mitunter kostenfrei die orangery nutzen können zum Beispiel als
            Treffpunkt mit potenziellen Partner*innen.
          </p>

          <p className="mb-3 text-justify">
            Ziel der Zusammenarbeit ist es, die Interessen und Communities, die
            beide Seiten mitbringen, näher zusammenzubringen und so den Weg für
            produktive gemeinsame Projekte zu ebnen – vom interdisziplinären
            Austausch bis hin zu Gastvorträgen oder -Vorlesungen und Workshops.
            Die Studierenden der HOST können zudem von den bestehenden
            Netzwerken der orangery profitieren, gerade wenn sie selbst
            vorhaben, zu gründen. „Die orangery ist ein interessanter Player im
            Gründungsökosystem“, ordnet Doreen Mlodzik,
            Gründungsberatungs-Agentin für die hochschulübergreifende
            Gründungsagentur Stapellauf NORD°OST° an der HOST, ein. Als solcher
            wird der Coworking-Space auch am 4. Mai 2023 mit anderen Partnern in
            der Startup-Lounge auf der SUPA (Stralsunder Unternehmens-,
            Praktikanten- und Absolventenbörse) vertreten sein.
          </p>

          <p className="mb-3 text-justify">
            Sebastian und Dominik Groenen hatten die orangery 2018 in Hildesheim
            gegründet und betreiben heute neun Coworking-Spaces, in diesem Jahr
            werden zudem vier weitere eröffnen. Den Stralsunder Space führen sie
            seit 2020.
          </p>

          <p className="mb-3 text-justify">
            Mit geballten Kräften sollen in diesem Jahr gemeinsame
            Veranstaltungen zu den Themen Unternehmensgründung, Start-ups oder
            auch Accelerator-Programmen in der orangery, Am Fischmarkt 13a,
            stattfinden. Zudem können Studierende und Mitarbeitende der HOST die
            orangery für weitere interdisziplinäre Events nutzen.
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1">Lennart Deorocki</p>
          <p className="mb-1">Community Manager</p>
          <p className="mb-1">orangery Stralsund</p>
          <p className="mb-1">Telefon: 01520 5639058</p>
          <p className="mb-1">
            <a
              href={`mailto:l.deorocki@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              l.deorocki@orangery.io
            </a>
          </p>
          <p className="mb-1 mt-4">Doreen Mlodzik</p>
          <p className="mb-1">Agentin Gründerberatung</p>
          <p className="mb-1">Hochschule Stralsund</p>
          <p className="mb-1">Telefon: 03831 - 45 70 62</p>
          <p className="mb-1">
            <a
              href={`mailto:doreen.mlodzik@hochschule-stralsund.de`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              doreen.mlodzik@hochschule-stralsund.de
            </a>
          </p>
        </>
      ),
    },
    en: {
      cleanTitle:
        "Orangery und die Hochschule Stralsund intensivieren ihre Kooperation",
      title: (
        <>
          <span className="text-brand">Orangery</span> und die{" "}
          <span className="text-brand">Hochschule Stralsund</span> intensivieren
          ihre Kooperation
        </>
      ),
      imageSrc: orangeryHostImage,
      date: "Pressemitteilung 31. März 2023",
      summary:
        "Gemeinsame Projekte mit einem Fokus auf Gründen und Startups im Coworking-Space am Stralsunder Hafen sollen Interessen und Communities näher zusammenbringen.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Gemeinsame Projekte mit einem Fokus auf Gründen und Startups im
            Coworking-Space am Stralsunder Hafen sollen Interessen und
            Communities näher zusammenbringen.
          </p>

          <p className="mb-3 text-justify">
            Sich bereichern, austauschen, zusammenarbeiten – die orangery, der
            Coworking-Space am Stralsunder Hafen, und die Hochschule Stralsund
            (HOST) intensivieren ihre Kooperation. Mit der Besetzung des neuen
            Community Managers, Lennart Deorocki, werden gemeinsame Formate
            geplant. „Wir wollen unser Haus mit viel Leben füllen und frischen
            Startups, die finanziell noch nicht oben auf sind, eine Plattform
            und Raum bieten“, erklärt Lennart Deorocki. Konkret bedeutet das,
            dass Studierende der HOST und vorzugsweise studentische Gründerteams
            mitunter kostenfrei die orangery nutzen können zum Beispiel als
            Treffpunkt mit potenziellen Partner*innen.
          </p>

          <p className="mb-3 text-justify">
            Ziel der Zusammenarbeit ist es, die Interessen und Communities, die
            beide Seiten mitbringen, näher zusammenzubringen und so den Weg für
            produktive gemeinsame Projekte zu ebnen – vom interdisziplinären
            Austausch bis hin zu Gastvorträgen oder -Vorlesungen und Workshops.
            Die Studierenden der HOST können zudem von den bestehenden
            Netzwerken der orangery profitieren, gerade wenn sie selbst
            vorhaben, zu gründen. „Die orangery ist ein interessanter Player im
            Gründungsökosystem“, ordnet Doreen Mlodzik,
            Gründungsberatungs-Agentin für die hochschulübergreifende
            Gründungsagentur Stapellauf NORD°OST° an der HOST, ein. Als solcher
            wird der Coworking-Space auch am 4. Mai 2023 mit anderen Partnern in
            der Startup-Lounge auf der SUPA (Stralsunder Unternehmens-,
            Praktikanten- und Absolventenbörse) vertreten sein.
          </p>

          <p className="mb-3 text-justify">
            Sebastian und Dominik Groenen hatten die orangery 2018 in Hildesheim
            gegründet und betreiben heute neun Coworking-Spaces, in diesem Jahr
            werden zudem vier weitere eröffnen. Den Stralsunder Space führen sie
            seit 2020.
          </p>

          <p className="mb-3 text-justify">
            Mit geballten Kräften sollen in diesem Jahr gemeinsame
            Veranstaltungen zu den Themen Unternehmensgründung, Start-ups oder
            auch Accelerator-Programmen in der orangery, Am Fischmarkt 13a,
            stattfinden. Zudem können Studierende und Mitarbeitende der HOST die
            orangery für weitere interdisziplinäre Events nutzen.
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1">Lennart Deorocki</p>
          <p className="mb-1">Community Manager</p>
          <p className="mb-1">orangery Stralsund</p>
          <p className="mb-1">Telefon: 01520 5639058</p>
          <p className="mb-1">
            <a
              href={`mailto:l.deorocki@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              l.deorocki@orangery.io
            </a>
          </p>
          <p className="mb-1 mt-4">Doreen Mlodzik</p>
          <p className="mb-1">Agentin Gründerberatung</p>
          <p className="mb-1">Hochschule Stralsund</p>
          <p className="mb-1">Telefon: 03831 - 45 70 62</p>
          <p className="mb-1">
            <a
              href={`mailto:doreen.mlodzik@hochschule-stralsund.de`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              doreen.mlodzik@hochschule-stralsund.de
            </a>
          </p>
        </>
      ),
    },
  },
  {
    key: "barmer-x-orangery-ein-gesunder-start-ins-neue-jahr",
    de: {
      cleanTitle: "BARMER x orangery – ein gesunder Start ins neue Jahr",
      title: (
        <>
          <span className="text-brand">BARMER</span> x{" "}
          <span className="text-brand">orangery</span> – ein gesunder Start ins
          neue Jahr
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Pressemitteilung 05. Januar 2023",
      summary:
        "Die orangery möchte ihrer Community nicht nur einen Ort zum Arbeiten bieten, sondern auch verschiedene Lebensbereiche neben der Arbeit integrieren. Das Thema Work-Life-Blending spielt bei uns eine besondere Rolle, hierbei werden Freizeit und Arbeit direkt miteinander kombiniert, wodurch sich viele neue Perspektiven ergeben. Um diese Mission voranzutreiben, kooperieren wir mit der BARMER.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Die orangery möchte ihrer Community nicht nur einen Ort zum Arbeiten
            bieten, sondern auch verschiedene Lebensbereiche neben der Arbeit
            integrieren. Das Thema Work-Life-Blending spielt bei uns eine
            besondere Rolle, hierbei werden Freizeit und Arbeit direkt
            miteinander kombiniert, wodurch sich viele neue Perspektiven
            ergeben. Um diese Mission voranzutreiben, kooperieren wir mit der
            BARMER.
          </p>

          <p className="mb-3 text-justify">
            Wir wollen für unsere gesamte Community verschiedene Angebote
            schaffen, die die Gesundheit und auch die Fitness unterstützen.
            Wichtig ist hierbei natürlich auch die mentale Gesundheit und der
            Ausgleich zum Arbeitsalltag. Hierfür kooperieren wir mit der BARMER
            und haben so die Möglichkeit verschiedene Angebote und
            Veranstaltungen für unsere Community zu realisieren. In diesem Jahr
            beschäftigen wir uns im Rahmen dieser Kooperation besonders mit den
            Themen Motivation, Ernährung, Fitness & Bewegung, oder auch mentaler
            Gesundheit.
          </p>

          <p className="mb-3 text-justify">
            Den Anfang macht unsere Schrittechallenge im Februar.
            Bewegungsmangel führt zu zahlreichen gesundheitlichen
            Risikofaktoren. Daher ist es wichtig, ausreichend Bewegung in den
            Alltag zu integrieren, gerade wenn beruflich viel Zeit am
            Schreibtisch verbracht wird.
          </p>

          <p className="mb-3 text-justify">
            Wer Lust hat, seine mentale und körperliche Gesundheit direkt am
            Anfang des Jahres positiv zu beeinflussen, ist herzlich eingeladen,
            an unserer Schrittechallenge teilzunehmen. Weitere Details zum
            Ablauf und der Anmeldung folgen.
          </p>

          <p className="mb-3 text-justify">
            Wir freuen uns sehr auf die Zusammenarbeit und die gemeinsamen
            Projekte.
          </p>
        </>
      ),
      contacts: (
        <>
          {/* <p className="mb-1 mt-12">
          Pressekontakt:
        </p>
        <p className="mb-1">
          orangery coworking GmbH
        </p>
        <p className="mb-1">
          Svenja Schutta
        </p>
        <p className="mb-1">
          <a href={`mailto:s.schutta@orangery.io`} className="text-indigo-500 hover:text-indigo-900">s.schutta@orangery.io</a>
        </p> */}
        </>
      ),
    },
    en: {
      cleanTitle: "BARMER x orangery – a healthy start to the new year",
      title: (
        <>
          <span className="text-brand">BARMER</span> x{" "}
          <span className="text-brand">orangery</span> – a healthy start to the
          new year
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Press Release January 5, 2023",
      summary:
        "The orangery not only wants to offer its community a place to work, but also to integrate different areas of life besides work. The topic of work-life-blending plays a special role for us. Here, leisure time and work are directly combined, resulting in many new perspectives. To advance this mission, we are cooperating with BARMER. ",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            The orangery not only wants to offer its community a place to work,
            but also to integrate different areas of life besides work. The
            topic of work-life-blending plays a special role for us. Here,
            leisure time and work are directly combined, resulting in many new
            perspectives. To advance this mission, we are cooperating with
            BARMER.
          </p>

          <p className="mb-3 text-justify">
            We want to create various offers for our entire community that
            support health and also fitness. Of course, mental health and a
            balance to the daily work routine are also important here. For this
            we cooperate with BARMER and have the possibility to realize
            different offers and events for our community. This year, within the
            framework of this cooperation, we are focusing on the topics of
            motivation, nutrition, fitness & exercise, and mental health.
          </p>

          <p className="mb-3 text-justify">
            We will start with our step challenge in February. Lack of exercise
            leads to numerous health risk factors. Therefore, it is important to
            integrate sufficient exercise into everyday life, especially if you
            spend a lot of time at your desk at work.
          </p>

          <p className="mb-3 text-justify">
            Anyone who would like to positively influence their mental and
            physical health right at the beginning of the year is cordially
            invited to participate in our Step Challenge. More details about the
            process and registration will follow.
          </p>

          <p className="mb-3 text-justify">
            We are very much looking forward to the cooperation and the joint
            projects.
          </p>
        </>
      ),
      contacts: (
        <>
          {/* <p className="mb-1 mt-12">
          Press contact:
        </p>
        <p className="mb-1">
          orangery coworking GmbH
        </p>
        <p className="mb-1">
          Svenja Schutta
        </p>
        <p className="mb-1">
          <a href={`mailto:s.schutta@orangery.io`} className="text-indigo-500 hover:text-indigo-900">s.schutta@orangery.io</a>
        </p> */}
        </>
      ),
    },
  },
  {
    key: "one-coworking-x-orangery",
    de: {
      cleanTitle: "One Coworking x orangery",
      title: (
        <>
          One <span className="text-brand">Coworking</span> x{" "}
          <span className="text-brand">orangery</span>
        </>
      ),
      imageSrc: image221214,
      date: "Pressemitteilung 14. Dezember 2022",
      summary:
        "Die orangery und One Coworking schließen sich zusammen, um so das Coworking Konzept noch weiter zu verbreiten. Zukünftig werden die Spaces der orangery in das Netzwerk von One Coworking integriert. Damit erhält zum einen die gesamte orangery Community Zugang zum kompletten One Coworking Netzwerk, zum anderen können die orangery Workspaces zukünftig auch über die OneCo App gefunden und gebucht werden. Die Vision der orangery für 2023 ist eine nahtlose Integration in beide Richtungen, an der derzeit weiter gearbeitet wird. Das orangery Team freut sich sehr auf diese Zusammenarbeit!",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Die orangery und One Coworking schließen sich zusammen, um so das
            Coworking Konzept noch weiter zu verbreiten. Zukünftig werden die
            Spaces der orangery in das Netzwerk von One Coworking integriert.
            Damit erhält zum einen die gesamte orangery Community Zugang zum
            kompletten One Coworking Netzwerk, zum anderen können die orangery
            Workspaces zukünftig auch über die OneCo App gefunden und gebucht
            werden. Die Vision der orangery für 2023 ist eine nahtlose
            Integration in beide Richtungen, an der derzeit weiter gearbeitet
            wird. Das orangery Team freut sich sehr auf diese Zusammenarbeit!
          </p>

          <p className="mb-1 text-justify">
            One Coworking bietet flexible Arbeitsräume auf Abruf für
            Einzelpersonen und Teams von Hybrid- und Remote-Unternehmen jeder
            Größe. Ihr Netzwerk verbindet mehr als 250 hochklassige Coworking
            Spaces auf der ganzen Welt und ermöglicht es den Menschen, von
            verschiedenen Spaces aus frei zu arbeiten, alles mit einer App.
            Mitglieder haben die Möglichkeit, verschiedene Coworking Spaces
            auszuprobieren, verschiedene Communities kennen zu lernen und sich
            weltweit zu vernetzen - und das alles mit nur einer Mitgliedschaft.
            One Coworking hat es sich zur Aufgabe gemacht, die Zukunft der
            Arbeit zu verändern und die Kraft des Coworking in die
            Arbeitsstrategien der Menschen einzubringen, die aus der Ferne oder
            in Mischformen arbeiten.
          </p>
          <p className="mb-3 text-justify">
            Mit der OneCo App können Einzelpersonen und Teams auf Arbeits- und
            Besprechungsräume zugreifen, wo und wann immer sie wollen. Digitale
            Nomaden und Remote Worker können die App nutzen, um einen geeigneten
            Arbeitsplatz oder Meetingraum in ihrer Nähe zu finden, auf Abruf
            einzuchecken und von überall aus zu arbeiten, sich zu vernetzen und
            inspirieren zu lassen. One Coworking bringt auch viele hochwertige
            kleine und lokale Spaces zusammen, damit sie eine größere Reichweite
            erfahren und gemeinsam stärker agieren können.
          </p>

          <p className="mb-3 text-justify">
            Die orangery wurde 2018 von den beiden Brüdern Dominik und Sebastian
            Groenen gegründet. Beide verfolgen seitdem die Vision, das sonst nur
            in Großstädten auffindbare Coworking-Konzept auch in B- und
            C-Städten zu etablieren. Die Coworking Spaces der orangery sollen so
            auch abseits der Metropolen als flexible, inspirierende und moderne
            Bürolösung für alle dienen, die dem Homeoffice entfliehen möchten
            oder mehr Platz für ihr Team benötigen. Vom Arbeiten in
            abschließbaren Einzelbüros, bis hin zu einem flexiblen Arbeitsplatz
            im Open Space Bereich, aber auch Meetings mit GeschäftspartnerInnen
            ist hier alles möglich. Zusätzlich können die Coworker aus den
            verschiedensten Bereichen ständig in den Austausch untereinander
            treten, hierfür eignen sich beispielsweise die verschiedenen Lounge
            Bereiche oder auch die eigens entwickelte App der orangery zur
            digitalen Vernetzung untereinander. “Wir wollen nicht nur Büroräume
            für unsere Coworker schaffen. Unser Ziel ist es, mit zahlreichen
            Services und Add-Ons eine inspirierende und angenehme Umgebung zu
            kreieren, die zu viel mehr als nur zum Arbeiten einlädt.” so Dominik
            Groenen – Founder und CEO der orangery. Die Orangery ist
            mittlerweile an 9 Standorten vertreten und viele weitere sind
            abseits der Metropolen in der DACH-Region in den nächsten Jahren
            geplant. Gerade nach der Pandemie lösen die beiden Brüder damit ein
            echtes Problem für ihre Kunden. Zum einen werden die Pendelzeiten
            reduziert und zum anderen gewinnen unsere Kunden dadurch wirklich
            wertvolle Zeit, um diese wiederum mit Ihrer Familie, Freunden, Sport
            oder anderen Freizeitaktivitäten zu verbringen.
          </p>
        </>
      ),
      contacts: (
        <>
          {/* <p className="mb-1 mt-12">
          Pressekontakt:
        </p>
        <p className="mb-1">
          orangery coworking GmbH
        </p>
        <p className="mb-1">
          Svenja Schutta
        </p>
        <p className="mb-1">
          <a href={`mailto:s.schutta@orangery.io`} className="text-indigo-500 hover:text-indigo-900">s.schutta@orangery.io</a>
        </p> */}
        </>
      ),
    },
    en: {
      cleanTitle: "One Coworking x orangery",
      title: (
        <>
          One <span className="text-brand">Coworking</span> x{" "}
          <span className="text-brand">orangery</span>
        </>
      ),
      imageSrc: image221214,
      date: "Press Release December 14, 2022",
      summary:
        "The orangery and One Coworking are joining forces to spread the coworking concept even further. In the future, the orangery's spaces will be integrated into the One Coworking network. On the one hand, this will give the entire orangery community access to the complete One Coworking network, and on the other hand, the orangery workspaces can also be found and booked via the OneCo app in the future. The orangery vision for 2023 is seamless integration in both directions, which is currently being worked on further. The orangery team is very excited about this collaboration!",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            The orangery and One Coworking are joining forces to spread the
            coworking concept even further. In the future, the orangery's spaces
            will be integrated into the One Coworking network. On the one hand,
            this will give the entire orangery community access to the complete
            One Coworking network, and on the other hand, the orangery
            workspaces can also be found and booked via the OneCo app in the
            future. The orangery vision for 2023 is seamless integration in both
            directions, which is currently being worked on further. The orangery
            team is very excited about this collaboration!
          </p>

          <p className="mb-1 text-justify">
            One Coworking provides on-demand, flexible workspaces for
            individuals and teams of hybrid and remote companies of all sizes.
            Their network connects more than 250 highly ranked coworking spaces
            around the world and allows people to work freely from different
            spaces, all using one App. Members have the opportunity to try out
            different spaces, get to know various communities and network their
            way around the world, all with only one membership. One Coworking is
            on a mission to transform the future of work and bring the power of
            coworking to people's remote and hybrid work strategies.
          </p>
          <p className="mb-3 text-justify">
            With the OneCo App, individuals and teams can access workspaces and
            meeting rooms wherever and whenever they need. Digital nomads and
            remote workers can use the app to find a suitable coworking or
            meeting space near them, check in on-demand to work, network and get
            inspired from anywhere. One Coworking brings together many high
            quality small and local spaces too, allowing them to experience a
            wider reach and act stronger together.
          </p>

          <p className="mb-3 text-justify">
            The orangery was founded in 2018 by two brothers, Dominik and
            Sebastian Groenen. Since then, both have been pursuing the vision of
            establishing the coworking concept, which can otherwise only be
            found in big cities, in B- and C-cities as well. The coworking
            spaces of orangery are intended to serve as a flexible, inspiring
            and modern office solution for anyone who wants to escape the home
            office or needs more space for their team, even away from the
            metropolises. From working in lockable individual offices, to a
            flexible workplace in the open space area, but also meetings with
            business partners, everything is possible here. In addition,
            coworkers from a wide variety of fields can constantly interact with
            each other, for this purpose, for example, the various lounge areas
            or even the orangery's specially developed app for digital
            networking among themselves. "We don't just want to create office
            space for our coworkers. Our goal is to create an inspiring and
            pleasant environment with numerous services and add-ons that invites
            you to do much more than just work," says Dominik Groenen - Founder
            and CEO of the orangery. The orangery is now present in 9 locations
            and many more are planned away from the metropolises in the DACH
            region in the coming years. Especially after the pandemic, the two
            brothers solve a real problem for their customers. On the one hand,
            commuting times are reduced and on the other hand, our customers
            gain really valuable time to spend with their family, friends,
            sports or other leisure activities.
          </p>
        </>
      ),
      contacts: (
        <>
          {/* <p className="mb-1 mt-12">
          Press contact:
        </p>
        <p className="mb-1">
          orangery coworking GmbH
        </p>
        <p className="mb-1">
          Svenja Schutta
        </p>
        <p className="mb-1">
          <a href={`mailto:s.schutta@orangery.io`} className="text-indigo-500 hover:text-indigo-900">s.schutta@orangery.io</a>
        </p> */}
        </>
      ),
    },
  },
  {
    key: "coworking-mitten-in-der-innenstadt-von-ulm",
    de: {
      cleanTitle: "Coworking mitten in der Innenstadt von Ulm",
      title: (
        <>
          <span className="text-brand">Coworking</span> mitten in der Innenstadt
          von <span className="text-brand">Ulm</span>
        </>
      ),
      imageSrc: image221213_2,
      date: "Pressemitteilung 13. Dezember 2022",
      summary:
        "Im Frühjahr 2023 eröffnet im neuen Quartier Sedelhöfe in Ulm ein neuer Standort der deutschlandweiten Coworking Space Anbieter orangery. Die Sedelhöfe befinden sich direkt in der Fußgängerzone am Albert-Einstein-Platz im Herzen Ulms und in unmittelbarer Nähe zum Bahnhof.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Im Frühjahr 2023 eröffnet im neuen Quartier Sedelhöfe in Ulm ein
            neuer Standort der deutschlandweiten Coworking Space Anbieter
            orangery. Die Sedelhöfe befinden sich direkt in der Fußgängerzone am
            Albert-Einstein-Platz im Herzen Ulms und in unmittelbarer Nähe zum
            Bahnhof.
          </p>

          <p className="mb-3 text-justify">
            Die neue orangery Location verteilt sich hier auf einer Fläche von
            über 2400 m². In den Sedelhöfen werden verschiedene Lebensbereiche
            miteinander kombiniert – Büroflächen befinden sich hier in direkter
            Nähe zur Gastronomie, Wohnungen, aber auch Einzelhandel, Wellness
            und Arztpraxen finden hier ausreichend Platz. Dieses Konzept passt
            optimal zur orangery! Bei der orangery handelt es sich nicht einfach
            nur um einen Anbieter von Büroflächen, vielmehr wird hier eine
            inspirierende und kreative Umgebung geschaffen, in der jeder
            herzlich Willkommen ist und seine vollen Potentiale ausschöpfen
            kann. Neben der Büroinfrastruktur bietet die orangery ein
            Full-Service-Angebot, jeder Coworker hat so die Möglichkeit sich auf
            die Schönen Dinge im Leben zu konzentrieren und zahlreiche Aufgaben
            werden von orangery Team übernommen. In die Coworking Spaces werden
            außerdem auch viele Lebensbereiche integriert – vom Fitnessraum, bis
            zur Kinderspielecke und den zahlreichen Lounge Areas, sollte für
            jeden das Richtige dabei sein. In Ulm wird zudem auch ein neues
            roots’n’seeds Cafe ins Erdgeschoss einziehen. Die neuen Coworker
            können sich dort in der Mittagspause eine leckere Bowl, einen
            frischen Bagel oder richtig guten Kaffee schmecken lassen.
          </p>

          <p className="mb-3 text-justify">
            Die orangery wurde 2018 von den beiden Brüdern Dominik und Sebastian
            Groenen gegründet. Beide verfolgen seitdem die Vision, das sonst nur
            in Großstädten auffindbare Coworking-Konzept auch in B- und
            C-Städten zu etablieren. Die Coworking Spaces der orangery sollen so
            auch abseits der Metropolen als flexible, inspirierende und moderne
            Bürolösung für alle dienen, die dem Homeoffice entfliehen möchten
            oder mehr Platz für ihr Team benötigen. Vom Arbeiten in
            abschließbaren Einzelbüros, bis hin zu einem flexiblen Arbeitsplatz
            im Open Space Bereich, aber auch Meetings mit GeschäftspartnerInnen
            ist hier alles möglich. Zusätzlich können die Coworker aus den
            verschiedensten Bereichen ständig in den Austausch untereinander
            treten, hierfür eignen sich beispielsweise die verschiedenen Lounge
            Bereiche oder auch die eigens entwickelte App der orangery zur
            digitalen Vernetzung untereinander. “Wir wollen nicht nur Büroräume
            für unsere Coworker schaffen. Unser Ziel ist es, mit zahlreichen
            Services und Add-Ons eine inspirierende und angenehme Umgebung zu
            kreieren, die zu viel mehr als nur zum Arbeiten einlädt.” so Dominik
            Groenen – Founder und CEO der orangery. Die Orangery ist
            mittlerweile an 9 Standorten vertreten und viele weitere sind
            abseits der Metropolen in der DACH-Region in den nächsten Jahren
            geplant. Gerade nach der Pandemie lösen die beiden Brüder damit ein
            echtes Problem für ihre Kunden. Zum einen werden die Pendelzeiten
            reduziert und zum anderen gewinnen unsere Kunden dadurch wirklich
            wertvolle Zeit, um diese wiederum mit Ihrer Familie, Freunden, Sport
            oder anderen Freizeitaktivitäten zu verbringen.
          </p>

          <p className="mb-3 text-justify">
            Wer Interesse am Coworking und dem orangery Erlebnis hat, kann sich
            auf der{" "}
            <a
              href={`https://www.orangery.io/location/Ulm`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              Website
            </a>{" "}
            umsehen oder eine Mail mit Fragen oder Anliegen an{" "}
            <a
              href={`mailto:info@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              info@orangery.io
            </a>{" "}
            senden.
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Pressekontakt:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
    en: {
      cleanTitle: "Coworking in the heart of Ulm",
      title: (
        <>
          <span className="text-brand">Coworking</span> in the heart of{" "}
          <span className="text-brand">Ulm</span>
        </>
      ),
      imageSrc: image221213_2,
      date: "Press Release December 13, 2022",
      summary:
        "In spring 2023, a new location of the Germany-wide coworking space provider orangery will open in the new quarter Sedelhöfe in Ulm. The Sedelhöfe are located directly in the pedestrian zone at Albert-Einstein-Platz in the heart of Ulm and in close proximity to the train station.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            In spring 2023, a new location of the Germany-wide coworking space
            provider orangery will open in the new quarter Sedelhöfe in Ulm. The
            Sedelhöfe are located directly in the pedestrian zone at
            Albert-Einstein-Platz in the heart of Ulm and in close proximity to
            the train station.
          </p>

          <p className="mb-3 text-justify">
            The new orangery location is spread out here over an area of more
            than 2400 m². In the Sedelhöfe, different areas of life are combined
            with each other - office space is located here in direct proximity
            to gastronomy, apartments, but also retail, wellness and medical
            practices find sufficient space here. This concept fits perfectly
            with the orangery! The orangery is not simply a provider of office
            space, rather an inspiring and creative environment is created here,
            where everyone is welcome and can realize their full potential. In
            addition to the office infrastructure, the orangery offers a full
            service, so each coworker has the opportunity to focus on the finer
            things in life and numerous tasks are taken over by orangery team.
            In addition, many living areas are integrated into the coworking
            spaces - from the fitness room to the children's play corner and the
            numerous lounge areas, there should be something for everyone. In
            Ulm, a new roots'n'seeds cafe will also move into the first floor.
            The new coworkers can enjoy a delicious bowl, a fresh bagel or
            really good coffee there during their lunch break.
          </p>

          <p className="mb-3 text-justify">
            The orangery was founded in 2018 by the two brothers Dominik and
            Sebastian Groenen. Since then, both have been pursuing the vision of
            establishing the coworking concept, which can otherwise only be
            found in big cities, in B- and C-cities as well. The coworking
            spaces of orangery are intended to serve as a flexible, inspiring
            and modern office solution for anyone who wants to escape the home
            office or needs more space for their team, even away from the
            metropolises. From working in lockable individual offices, to a
            flexible workplace in the open space area, but also meetings with
            business partners, everything is possible here. In addition,
            coworkers from a wide variety of fields can constantly interact with
            each other, for this purpose, for example, the various lounge areas
            or even the orangery's specially developed app for digital
            networking among themselves. "We don't just want to create office
            space for our coworkers. Our goal is to create an inspiring and
            pleasant environment with numerous services and add-ons that invites
            you to do much more than just work," says Dominik Groenen - Founder
            and CEO of the orangery. The orangery is now present in 9 locations
            and many more are planned away from the metropolises in the DACH
            region in the coming years. Especially after the pandemic, the two
            brothers solve a real problem for their customers. On the one hand,
            commuting times are reduced and on the other hand, our customers
            gain really valuable time to spend with their family, friends,
            sports or other leisure activities.
          </p>

          <p className="mb-3 text-justify">
            Anyone interested in coworking and the orangery experience can check
            out the{" "}
            <a
              href={`https://www.orangery.io/location/Ulm`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              website
            </a>{" "}
            or send an email with questions or concerns to{" "}
            <a
              href={`mailto:info@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              info@orangery.io
            </a>
            .
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Press contact:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
  },
  {
    key: "orangery-coworking-im-neuen-advancis-campus-vor-den-toren-frankfurts",
    de: {
      cleanTitle:
        "orangery Coworking im neuen advancis Campus vor den Toren Frankfurts",
      title: (
        <>
          <span className="text-brand">orangery</span> Coworking im neuen{" "}
          <span className="text-brand">advancis Campus</span> vor den Toren
          Frankfurts
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Pressemitteilung 13. Dezember 2022",
      summary:
        "Im Spätsommer 2023 zieht die orangery mit einem neuen Coworking Space in den neuen Advancis Campus in der Monzastraße in Langen. Unweit von Frankfurt am Main entfernt entsteht hier ein innovativer urbaner Campus auf über 30.000m², der Leben, Arbeiten und Freizeit vereint.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Im Spätsommer 2023 zieht die orangery mit einem neuen Coworking
            Space in den neuen Advancis Campus in der Monzastraße in Langen.
            Unweit von Frankfurt am Main entfernt entsteht hier ein innovativer
            urbaner Campus auf über 30.000m², der Leben, Arbeiten und Freizeit
            vereint.
          </p>

          <p className="mb-3 text-justify">
            Die orangery wird eine Fläche von über 3000m² beziehen und arbeitet
            an diesem Projekt eng mit dem Investor Jan Meiswinkel zusammen. Jan
            Meiswinkel und sein Projektteam realisieren den Technologie Campus
            in Langen und planen eine Infrastruktur, die verschiedene
            Lebensbereiche miteinander vereint. Wohnungen und Büroräume finden
            hier genauso Platz wie Restaurants, eine Kita und Fitnessbereiche.
            Damit passt das Konzept des Campus perfekt zur orangery. In den
            orangery Coworking Spaces leben wir das Motto “shape the way you
            work. live. grow.”. In der orangery ist jeder Herzlich Willkommen
            und kann sich kreativ und produktiv an einem Flex Desk oder in einem
            Private Office ausleben und sich mit anderen Coworkern austauschen –
            ganz egal, ob Start Up, Freelancer, UnternehmerInnen oder
            Angestellte, für jeden ist das richtige Produkt dabei. Zusätzlich zu
            den flexiblen Bürolösungen kombiniert die orangery zukünftig
            Lounge-, Fitness- & Wellnessbereiche, Coffeeshops, Barbershops,
            Concierge & Laundry Services, sowie Kinderbetreuung und CoLiving
            Angebote mit den eigenen Work Spaces. Die Coworker profitieren von
            all diesen Angeboten und sparen sich aufgrund der Integration in die
            Spaces wertvolle Zeit, die sonst beispielsweise für die Anfahrt
            anfallen würde.
          </p>

          <p className="mb-3 text-justify">
            Die orangery wurde 2018 von den beiden Brüdern Dominik und Sebastian
            Groenen gegründet. Beide verfolgen seitdem die Vision, das sonst nur
            in Großstädten auffindbare Coworking-Konzept auch in B- und
            C-Städten zu etablieren. Die Coworking Spaces der orangery sollen so
            auch abseits der Metropolen als flexible, inspirierende und moderne
            Bürolösung für alle dienen, die dem Homeoffice entfliehen möchten
            oder mehr Platz für ihr Team benötigen. Vom Arbeiten in
            abschließbaren Einzelbüros, bis hin zu einem flexiblen Arbeitsplatz
            im Open Space Bereich, aber auch Meetings mit GeschäftspartnerInnen
            ist hier alles möglich. Zusätzlich können die Coworker aus den
            verschiedensten Bereichen ständig in den Austausch untereinander
            treten, hierfür eignen sich beispielsweise die verschiedenen Lounge
            Bereiche oder auch die eigens entwickelte App der orangery zur
            digitalen Vernetzung untereinander. “Wir wollen nicht nur Büroräume
            für unsere Coworker schaffen. Unser Ziel ist es, mit zahlreichen
            Services und Add-Ons eine inspirierende und angenehme Umgebung zu
            kreieren, die zu viel mehr als nur zum Arbeiten einlädt.” so Dominik
            Groenen – Founder und CEO der orangery. Die Orangery ist
            mittlerweile an 9 Standorten vertreten und viele weitere sind
            abseits der Metropolen in der DACH-Region in den nächsten Jahren
            geplant. Gerade nach der Pandemie lösen die beiden Brüder damit ein
            echtes Problem für ihre Kunden. Zum einen werden die Pendelzeiten
            reduziert und zum anderen gewinnen unsere Kunden dadurch wirklich
            wertvolle Zeit, um diese wiederum mit Ihrer Familie, Freunden, Sport
            oder anderen Freizeitaktivitäten zu verbringen.
          </p>

          <p className="mb-3 text-justify">
            Wer Interesse am Coworking und dem orangery Erlebnis hat, kann sich
            auf der{" "}
            <a
              href={`https://www.orangery.io/location/Langen`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              Website
            </a>{" "}
            umsehen oder eine Mail mit Fragen oder Anliegen an{" "}
            <a
              href={`mailto:info@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              info@orangery.io
            </a>{" "}
            senden.
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Pressekontakt:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
    en: {
      cleanTitle:
        "orangery coworking in the new Advancis Campus just outside Frankfurt",
      title: (
        <>
          <span className="text-brand">orangery</span> coworking in the new{" "}
          <span className="text-brand">Advancis Campus</span> just outside
          Frankfurts
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Press Release December 13, 2022",
      summary:
        "In late summer 2023, orangery will move into the new Advancis Campus on Monzastraße in Langen with a new coworking space. Not far from Frankfurt am Main, an innovative urban campus is being created here on over 30,000m², combining living, working and leisure.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            In late summer 2023, orangery will move into the new Advancis Campus
            on Monzastraße in Langen with a new coworking space. Not far from
            Frankfurt am Main, an innovative urban campus is being created here
            on over 30,000m², combining living, working and leisure.
          </p>

          <p className="mb-3 text-justify">
            The orangery will occupy an area of over 3000m² and is working
            closely with the investor Jan Meiswinkel on this project. Jan
            Meiswinkel and his project team are realizing the technology campus
            in Langen and are planning an infrastructure that combines different
            areas of life. There is room for apartments and office space as well
            as restaurants, a daycare center and fitness areas. Thus, the
            concept of the campus fits perfectly with the orangery. In the
            orangery coworking spaces, we live the motto "shape the way you
            work. live. grow.". Everyone is welcome at the orangery and can be
            creative and productive at a flex desk or in a private office and
            exchange ideas with other coworkers - no matter if you are a start
            up, freelancer, entrepreneur or employee, there is something for
            everyone. In addition to the flexible office solutions, the orangery
            will in future combine lounge, fitness & wellness areas, coffee
            shops, barbershops, concierge & laundry services, as well as
            childcare and CoLiving offers with its own Work Spaces. Coworkers
            benefit from all of these offerings and, because they are integrated
            with the Spaces, save valuable time that would otherwise be spent
            traveling, for example.
          </p>

          <p className="mb-3 text-justify">
            The orangery was founded in 2018 by the two brothers Dominik and
            Sebastian Groenen. Since then, both have been pursuing the vision of
            establishing the coworking concept, which can otherwise only be
            found in large cities, in B and C cities as well. The coworking
            spaces of orangery are intended to serve as a flexible, inspiring
            and modern office solution for all those who want to escape the home
            office or need more space for their team, even away from the
            metropolises. From working in lockable individual offices, to a
            flexible workplace in the open space area, but also meetings with
            business partners, everything is possible here. In addition,
            coworkers from a wide variety of fields can constantly interact with
            each other, for this purpose, for example, the various lounge areas
            or even the orangery's specially developed app for digital
            networking among themselves. "We don't just want to create office
            space for our coworkers. Our goal is to create an inspiring and
            pleasant environment with numerous services and add-ons that invites
            you to do much more than just work," says Dominik Groenen - Founder
            and CEO of the orangery. The orangery is now present in 9 locations
            and many more are planned away from the metropolises in the DACH
            region in the coming years. Especially after the pandemic, the two
            brothers solve a real problem for their customers. On the one hand,
            commuting times are reduced and on the other hand, our customers
            gain really valuable time to spend with their family, friends,
            sports or other leisure activities.
          </p>

          <p className="mb-3 text-justify">
            Anyone interested in coworking and the orangery experience can check
            out the{" "}
            <a
              href={`https://www.orangery.io/location/Langen`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              website
            </a>{" "}
            or send an email with questions or concerns to{" "}
            <a
              href={`mailto:info@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              info@orangery.io
            </a>
            .
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Press contact:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
  },
  {
    key: "teilnahmebedingungen-orangery-adventskalender-gewinnspiel",
    cleanTitle: "Teilnahmebedingungen orangery Adventskalender-Gewinnspiel",
    title: (
      <>
        Teilnahmebedingungen <span className="text-brand">orangery</span>{" "}
        Adventskalender-<span className="text-brand">Gewinnspiel</span>
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 01. Dezember 2022",
    summary: (
      <>
        Veranstalter des Gewinnspiels („Gewinnspiel“) ist die orangery Coworking
        GmbH („Veranstalter“). Fragen im Zusammenhang mit dem Gewinnspiel sind
        an folgende E-Mailadresse{" "}
        <a
          href={`mailto:info@orangery.io`}
          className="text-indigo-500 hover:text-indigo-900"
        >
          info@orangery.io
        </a>{" "}
        zu richten.
      </>
    ),
    body: (
      <>
        <p className="mb-3 text-justify font-semibold">
          Veranstalter des Gewinnspiels („Gewinnspiel“) ist die orangery
          Coworking GmbH („Veranstalter“). Fragen im Zusammenhang mit dem
          Gewinnspiel sind an folgende E-Mailadresse{" "}
          <a
            href={`mailto:info@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            info@orangery.io
          </a>{" "}
          zu richten.
        </p>

        <p className="mb-3 text-justify font-semibold">Teilnahmeberechtigung</p>
        <p className="mb-3 text-justify">
          Diese Teilnahmebedingungen regeln die Teilnahme an dem Gewinnspiel
          „orangery Adventskalender-Gewinnspiel 2022“ des Veranstalters, das
          über den Instagram-Account der orangery beworben und auch durchgeführt
          wird. Erreichbar ist die Instagram-Seite der orangery unter
          https://www.instagram.com/orangery_coworking/ Die Durchführung des
          Gewinnspiels erfolgt auf Basis dieser Teilnahmebedingungen. Mit der
          Teilnahme an dem Gewinnspiel akzeptiert der Teilnehmer automatisch die
          folgenden Teilnahmebedingungen.
        </p>
        <p className="mb-3 text-justify">
          Teilnahmeberechtigt sind alle unbeschränkt geschäftsfähigen Personen,
          die zum Zeitpunkt der Teilnahme das 18. Lebensjahr vollendet und ihren
          Wohnsitz in Deutschland haben. An Minderjährige, die dennoch
          teilnehmen, werden keine Gewinne ausgeschüttet. Mitarbeiter des
          Veranstalters und/oder verbundener Unternehmen sowie alle an der
          Konzeption und Umsetzung des Gewinnspiels beteiligten Personen sind
          von der Teilnahme ausgeschlossen. Gewinnspielagenturen sowie
          automatisierte Gewinnspieldienste sind von der Teilnahme
          ausgeschlossen. Außerdem behält sich der Veranstalter vor, Personen
          bei Vorliegen eines wichtigen Grundes (z.B. Manipulationsversuch) von
          der Teilnahme auszuschließen.
        </p>
        <p className="mb-3 text-justify">
          Beim Gewinn “30 Tage Free Trial” sind bestehende Flex Desk, Hybrid
          Office, oder Private Office Kunden der orangery von der Teilnahme
          ausgeschlossen.
        </p>

        <p className="mb-3 text-justify font-semibold">Teilnahmezeitraum</p>
        <p className="mb-3 text-justify">
          Die Durchführung des Gewinnspiels erfolgt vom 01.12.2022 bis zum
          24.12.2022 („Teilnahmezeitraum“).
        </p>

        <p className="mb-3 text-justify font-semibold">
          Durchführung und Ablauf
        </p>
        <p className="mb-3 text-justify">
          Das Gewinnspiel wird auf dem Instagram-Kanal der orangery bekannt
          gegeben. Es wird täglich im angegebenen Zeitraum um 9:00 Uhr der
          Gewinn des Tages bekanntgegeben. Zur Teilnahme muss der Teilnehmer
          unter dem Instagram Beitrag bis 24 Uhr des Tages einen Kommentar
          veröffentlichen, in welchem der Teilnehmer ein weiteres Instagram
          Profil markiert.
        </p>

        <p className="mb-3 text-justify font-semibold">Gewinn</p>
        <p className="mb-3 text-justify">
          Unter allen teilnahmeberechtigten Teilnehmern innerhalb des
          Teilnahmezeitraums entscheidet das Zufallsprinzip über die Gewinner.
          Die Auslosung der Gewinne erfolgt am nächsten Werktag. Die Gewinner
          haben Ansprüche auf die folgenden Gewinne:
        </p>
        <p className="mb-3 text-justify">
          2x orangery Tasse, 2x orangery T-Shirt, 3x 30 Tage “Free Trial”, 2x
          orangery Rucksack, 2x Meetingraum (6 Personen, 4 Stunden), Nikolaus
          Körbchen, Happy Painting, Tagespässe, 2x BARMER Feel Good Bag,
          Adventsfrühstück im Roots n’ Seeds, Kaffeepaket Roots n’ Seeds, 1
          Woche Flex Desk, Social Media Workshop mit dem orangery Marketing
          Team, Team Workshop, Branding Workshop mit Torben Dill, Wochenende in
          Stralsund (2 Nächte für 2 Personen in orangery Coliving Wohnung in
          Stralsund)
        </p>

        <p className="mb-3 text-justify font-semibold">
          Gewinnbenachrichtigung
        </p>
        <p className="mb-3 text-justify">
          Teilnahme über Instagram: Der Gewinner wird nach der Auslosung
          schriftlich per Privatnachricht über die App Instagram benachrichtigt
          und muss sich binnen 14 Tagen nach Versendung der Benachrichtigung
          zurückmelden. Meldet sich der Gewinner nicht innerhalb der Frist,
          verliert er alle Rechte auf den Gewinn und ein neuer Gewinner wird
          ausgelost. Für den/einen neuen Gewinner gilt die Regelung dieser
          Ziffer ebenso. Meldet der Teilnehmer sich zurück, werden ihm weitere
          Informationen zur Einlösung des Gewinns übersandt.
        </p>

        <p className="mb-3 text-justify font-semibold">
          Barauszahlung, Übertragung, eigenmächtige Änderung des Gewinnes etc.
        </p>
        <p className="mb-3 text-justify">
          Eine (Bar-)Auszahlung des Gewinns ist nicht möglich.
        </p>

        <p className="mb-3 text-justify font-semibold">Haftungsausschluss</p>
        <p className="mb-3 text-justify">
          Der Veranstalter wird mit der Aushändigung des Gewinns von allen
          Verpflichtungen frei, sofern sich nicht aus diesen Regelungen schon
          ein früherer Zeitpunkt ergibt.
        </p>
        <p className="mb-3 text-justify">
          Jegliche Haftung des Veranstalters, der Erfüllungsgehilfen,
          Arbeitnehmer und Vertreter aus oder im Zusammenhang mit diesem
          Gewinnspiel, gleich aus welchem Rechtsgrund, ist auf Fälle von Vorsatz
          und grober Fahrlässigkeit und die fahrlässige Verletzung von
          Pflichten, die für die Erreichung des Vertragszwecks von wesentlicher
          Bedeutung sind, sogenannte Kardinalpflichten. „Kardinalpflichten“ sind
          solche Vertragspflichten, deren Erfüllung die ordnungsgemäße
          Vertragsführung erst ermöglicht und auf deren Einhaltung der
          Vertragspartner vertraut und auch vertrauen darf. Im Falle der
          fahrlässigen Verletzung einer Kardinalpflicht ist die Haftung des
          Veranstalters der Höhe nach auf den vertragstypischen vorhersehbaren
          Schaden beschränkt. Diese Beschränkungen gelten nicht für Schäden
          durch die Verletzung von Leben, Körper und/oder Gesundheit oder für
          eine Haftung nach dem Produkthaftungsgesetz. Voranstehende
          Haftungsbeschränkungen gelten insbesondere auch für Schäden, die durch
          Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung, bei
          Störungen der technischen Anlagen oder der Services, unrichtige
          Inhalte, Verlust oder Löschung von Daten und Viren oder in sonstiger
          Weise bei der Teilnahme an dem Gewinnspiel entstehen können, es sei
          denn, dass solche Schäden von dem Veranstalter vorsätzlich oder grob
          fahrlässig verursacht wurden.
        </p>
        <p className="mb-3 text-justify">
          Ggf. anfallende finanzielle Aufwendungen für die
          Einlösung/Durchführung des Gewinns (z.B. Unterkunft, erforderliche
          Umbuchungen aufgrund eigenen Verschuldens etc.) sind vom Teilnehmer
          und seiner Begleitung selbst zu tragen.
        </p>

        <p className="mb-3 text-justify font-semibold">
          Beendigung des Gewinnspiels
        </p>
        <p className="mb-3 text-justify">
          Das Gewinnspiel kann durch den Veranstalter entweder eingestellt,
          abgebrochen, ausgesetzt oder verändert werden, falls unvorhergesehene,
          außerhalb des Veranstalters liegende Umstände eintreten, welche die
          planmäßige Durchführung des Gewinnspiels hindern. Der Veranstalter
          wird die vorzeitige Beendigung unverzüglich bekanntgeben. Eine
          Inanspruchnahme des Veranstalters ist auch in diesem Fall
          ausgeschlossen.
        </p>

        <p className="mb-3 text-justify font-semibold">Rechteeinräumung</p>
        <p className="mb-3 text-justify">
          Der Teilnehmer erklärt sich damit einverstanden, dass er mit seinem
          Namen sowie im Falle eines Gewinns als Gewinner in den Profilen des
          Veranstalters in sozialen Netzwerken (z.B. Facebook, Instagram)
          und/oder auf der Webseite des Veranstalters (
          <a
            href={`www.orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            www.orangery.io
          </a>
          ) genannt wird.
        </p>

        <p className="mb-3 text-justify font-semibold">Abstandserklärung</p>
        <p className="mb-3 text-justify">
          Die vom Veranstalter auf der App Instagram durchgeführten Gewinnspiele
          stehen in keiner Verbindung zu Instagram und werden in keiner Weise
          von Instagram gesponsert, unterstützt oder organisiert. Für keinen der
          im Rahmen dieser Gewinnspiele veröffentlichten Inhalte ist Instagram
          verantwortlich. Insbesondere ist nicht Instagram Veranstalter des
          Gewinnspiels.
        </p>
        <p className="mb-3 text-justify">
          Der Empfänger der vom Teilnehmer bereitgestellten Informationen ist
          nicht Instagram, sondern der Veranstalter.
        </p>
        <p className="mb-3 text-justify">
          Der Teilnehmer kann gegenüber Instagram keine Ansprüche im
          Zusammenhang mit den Gewinnspielen des Veranstalters auf der App
          Instagram geltend machen.
        </p>

        <p className="mb-3 text-justify font-semibold">Datenschutz</p>
        <p className="mb-3 text-justify">
          Die von dem Teilnehmer angegebenen personenbezogenen Daten werden nur
          für die Durchführung des Gewinnspiels erhoben und danach innerhalb von
          drei Monaten wieder gelöscht.
        </p>
        <p className="mb-3 text-justify">
          Im Übrigen gelten die Datenschutzinformationen, abrufbar unter:{" "}
          <a
            href={`https://www.orangery.io/privacy-policy`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            https://www.orangery.io/privacy-policy
          </a>
          .
        </p>

        <p className="mb-3 text-justify font-semibold">Sonstiges</p>
        <p className="mb-3 text-justify">
          Der Rechtsweg ist ausgeschlossen. Der Veranstalter behält sich das
          Recht vor, dieses Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung
          zu unterbrechen oder vollständig zu beenden oder die
          Teilnahmebedingungen zu ändern. Dies gilt insbesondere, wenn die
          Gewährleistung eines ordnungsgemäßen Verlaufs aus technischen oder
          sonstigen Gründen nicht möglich ist.
        </p>
        <p className="mb-3 text-justify">
          Verstößt ein Teilnehmer gegen diese Teilnahmebedingungen, gegen
          gesetzliche Bestimmungen oder macht er unzutreffende Angaben, kann ihn
          der Veranstalter von der Teilnahme ausschließen, ohne hierfür Gründe
          angeben zu müssen.
        </p>
        <p className="mb-3 text-justify">
          Sollten einzelne Bestimmungen dieser Teilnahmebedingungen unwirksam,
          unzulässig oder undurchführbar sein oder werden, so lässt dies die
          Wirksamkeit der Teilnahmebedingungen im Übrigen unberührt. An die
          Stelle der unwirksamen, unzulässigen oder undurchführbaren Klausel
          treten Regelungen, deren Wirkungen der wirtschaftlichen Zielsetzung
          möglichst nahekommen.
        </p>
        <p className="mb-3 text-justify">
          Die Teilnahmebedingungen unterliegen dem Recht der Bundesrepublik
          Deutschland unter Ausschluss des UN-Kaufrechts.
        </p>

        <p className="mb-3 text-justify font-semibold">
          Viel Glück und Erfolg wünscht das orangery-Team
        </p>
      </>
    ),
    // contacts: <>
    //   <p className="mb-1 mt-12">
    //     Pressekontakt:
    //   </p>
    //   <p className="mb-1">
    //     orangery coworking GmbH
    //   </p>
    //   <p className="mb-1">
    //     Svenja Schutta
    //   </p>
    //   <p className="mb-1">
    //     <a href={`mailto:s.schutta@orangery.io`} className="text-indigo-500 hover:text-indigo-900">s.schutta@orangery.io</a>
    //   </p>
    // </>
  },
  {
    key: "orangery-nooka-space-startups-erfinden-gemeinsam-das-coworking-erlebnis-neu",
    de: {
      cleanTitle:
        "orangery & Nooka Space Startups erfinden gemeinsam das Coworking Erlebnis neu",
      title: (
        <>
          orangery & <span className="text-brand">Nooka Space</span> Startups
          erfinden gemeinsam das <span className="text-brand">Coworking</span>{" "}
          Erlebnis neu
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Pressemitteilung 03. November 2022",
      summary:
        "Orangery & Nooka Space, zwei Startups, die Innovationen in den Remote Work Bereich bringen, schließen sich zusammen und führen das Konzept des Flex Office on Demand in Deutschland ein, ein Service, der den Zugang zu hochmodernen, technologisch erweiterten Büroflächen ermöglicht.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Orangery & Nooka Space, zwei Startups, die Innovationen in den
            Remote Work Bereich bringen, schließen sich zusammen und führen das
            Konzept des Flex Office on Demand in Deutschland ein, ein Service,
            der den Zugang zu hochmodernen, technologisch erweiterten
            Büroflächen ermöglicht.
          </p>

          <p className="mb-1 text-justify">
            Dominik und Sebastian Groenen, die Gründer von orangery, sind seit
            2018 innovativ auf dem Markt für Arbeitsräume tätig, mit dem Wunsch,
            das Coworking-Konzept außerhalb der Großstädte zu etablieren und den
            Nutzern exklusive Funktionen eines Büroraums zu bieten, der
            Kreativität, Produktivität und urbane Mobilität fördert. Ihre Vision
            ist heute mit neun Standorten in Deutschland erfolgreich umgesetzt
            und viele weitere werden folgen.
          </p>
          <p className="mb-3 text-justify">
            In Zusammenarbeit mit Nooka Space, dem Start-up-Unternehmen, das das
            weltweit erste Konzept intelligenter und flexibler
            Nachbarschaftsbüros auf den Markt gebracht hat, bringt orangery eine
            neue Ebene der Innovation ein, indem es den traditionellen Büromarkt
            revolutioniert und ein Netzwerk von Coworking Spaces in secondary
            cities in ganz Deutschland entwickelt. Ziel ist es, das Arbeiten in
            B- und C-Städten attraktiver zu machen und die Abwanderungsrate
            junger Absolventen, Start-ups, Freiberufler, aber auch ansässiger
            Unternehmen in die Großstädte zu verringern.
          </p>

          <p className="mb-3 text-justify">
            <blockquote className="italic font-semibold text-gray-800 inline">
              „orangery steht für Flexibilität, deshalb wollen wir jedem
              ermöglichen, von überall aus zu arbeiten. In Zusammenarbeit mit
              Nooka Space sind wir dieser Vision einen Schritt näher gekommen.
              Wir planen, brandneue Nooka Cubes Smart-Office-Kabinen an
              Raststätten entlang von Autobahnen aufzustellen, damit die
              Menschen unterwegs von voll ausgestatteten Arbeitsplätzen aus
              arbeiten können, ohne dass sie z.B. von der Rückbank aus an einem
              wichtigen Meeting teilnehmen müssen”
            </blockquote>
            , so{" "}
            <span className="font-bold">
              Sebastian Groenen, Gründer der orangery
            </span>
            .
          </p>

          <p className="mb-3">
            <span className="font-bold">
              Sandu Băbășan, Co-Founder von Nooka Space
            </span>
            , fügte hinzu:{" "}
            <blockquote className="italic font-semibold text-gray-800 inline">
              „Gemeinsam mit unserem Geschäftspartner orangery wollen wir in
              Deutschland ein intelligentes Coworking-Netzwerk anbieten, um die
              Büroerfahrung eines jeden Angestellten oder Unternehmers zu
              verbessern und kreative und kollaborative Arbeitsumgebungen zu
              fördern. Unser Geschäftsmodell und die dahinter stehende
              Technologie ebnen den Weg für die Zukunft der Arbeit.”
            </blockquote>
          </p>

          <p className="mb-3">
            orangery bietet die Möglichkeit, flexibel und unkompliziert einen
            Arbeitsraum zu mieten. Neben den Coworking Spaces von orangery, die
            ausgestattete Arbeitsplätze in Einzelbüros, Großraumbüros und
            Konferenzräumen bieten, gibt es in Rostock und Hildesheim auch die
            Möglichkeit, die Bürokabine Nooka Cube zu buchen.
          </p>

          <p className="mb-1">
            <span className="font-bold">Nooka Cube</span> ist ein angenehmer und
            intimer Ort zum Arbeiten, eine Art Bürokabine, die von Nooka Space
            entworfen wurde, voll ausgestattet mit hochmodernen Möbeln und IoT
            integriert, so dass die Nutzer die volle Kontrolle über die
            intelligenten Funktionen haben. Das moderne Design lässt natürliches
            Licht von mehreren Seiten in den Raum eindringen, während die
            Architektur und die solide Struktur der Kabine ein hohes Maß an
            Sicherheit bieten und die Benutzer vor den äußeren
            Witterungsbedingungen schützen. Nooka Cube-Bürokabinen bieten ein
            hohes Maß an Privatsphäre und optimale Arbeitsbedingungen, indem sie
            eine Insel der Stille in einem offenen, überfüllten Bereich
            schaffen.
          </p>
          <p className="mb-1">
            Sowohl Nooka Space als auch orangery wollen ihren Kunden eine
            flexible Bürolösung anbieten, die das Arbeitsleben auf verschiedenen
            Ebenen verbessert. Daher haben beide Unternehmen eine eigene App
            entwickelt, um den Buchungsprozess zu automatisieren und die
            Arbeitserfahrung zu erleichtern.
          </p>
          <p className="mb-1">
            Die strategische Partnerschaft zwischen Nooka Space und orangery hat
            das Ziel, das Geschäftsmodell auf ganz Deutschland auszuweiten und
            eine Gemeinschaft von Nutzern zu schaffen, die zur nachhaltigen
            Entwicklung der Smart Cities beiträgt.
          </p>
          <p className="mb-3">
            Weitere Informationen über orangery und Nooka Space finden Sie unter{" "}
            <a
              href={`www.orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              www.orangery.io
            </a>{" "}
            und{" "}
            <a
              href={`www.nookaspace.com`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              www.nookaspace.com
            </a>
            .
          </p>

          <p className="text-sm mb-3">
            Die <span className="font-bold">orangery</span> wurde 2018 von
            Dominik und Sebastian Groenen - den Gründern und CEO - mit der
            Vision gegründet, das Coworking-Konzept in B- und C-Städten zu
            etablieren. Coworking Spaces sind immer noch hauptsächlich in
            Großstädten zu finden und genau das wollten die Groenen-Brüder
            ändern. Ihr Ziel ist es, einen flexiblen, inspirierenden und
            modernen Arbeitsplatz abseits der Großstädte zu bieten. Neben den
            Einzelbüros, den Schreibtischen im Open Space und den Meetingräumen,
            wollen sie eine Umgebung schaffen, in der die Coworker nicht einfach
            nur arbeiten können – ganz nach dem Motto work.live.grow. – werden
            zahlreiche Features in den Arbeitsalltag integriert, von denen die
            Coworker auf verschiedenen Ebenen profitieren. Dazu gehören zum
            Beispiel Fitnessbereiche oder die Integration eines Cafés in die
            Coworking Spaces. Die orangery hat es sich zur Aufgabe gemacht,
            ihren Coworkern ein möglichst entspanntes Arbeiten zu ermöglichen
            und sie von zahlreichen Aufgaben zu entlasten. Kaffee kochen,
            Inventarverwaltung, Post- und Paketannahme, Reinigung,
            Raumgestaltung - all das und vieles mehr ist im Full-Service-Paket
            der Orangery integriert. Die Orangery ist mittlerweile an 9
            Standorten vertreten und viele weitere sind abseits der Metropolen
            in der DACH-Region in den nächsten Jahren geplant.
          </p>

          <p className="text-sm mb-3">
            <span className="font-bold">Nooka Space</span> ist das Start-up, das
            das weltweit erste Konzept für intelligente und flexible
            Nachbarschaftsbüros entwickelt hat. Die Geschichte von Nooka begann
            im Jahr 2020 mit dem Wunsch, die Definition des Arbeitsplatzes neu
            zu schreiben und dabei zu helfen, die Büros der Zukunft zu schaffen:
            intelligenter, zugänglicher, flexibler, intimer und
            umweltfreundlicher. Das Geschäftsmodell von Nooka Space bietet die
            Möglichkeit, Räume zu mieten, auf Basis von Stundensätzen zu buchen,
            zu kaufen und die Einnahmen zu teilen und trägt so zum Konzept der
            flexiblen Räume und der urbanen Mobilität bei. Das Unternehmen
            bietet kompakte "Plug-and-Play"-Büroräume an, die aufgrund ihrer
            Spezifikationen fast überall Platz finden, sei es im öffentlichen
            Raum, in einem Wohngebiet, in einem Garten oder an einem stark
            frequentierten Ort. Derzeit bietet Nooka Space drei Büromodelle an:
            Nooka AIR (Nooka Air One und Nooka Air Two), die für Innenräume
            konzipiert sind, und Nooka Cube & Nooka One, die für Außenbereiche
            geeignet sind. Nooka Space hat sich zum Ziel gesetzt, eine weltweite
            hybride Arbeitsgemeinschaft aufzubauen, die auf Kreativität und
            Zusammenarbeit basiert. eine weltweite hybride Arbeitsgemeinschaft
            aufzubauen, die auf Kreativität und Zusammenarbeit basiert. Nooka
            Space hat Niederlassungen in den Niederlanden, Deutschland,
            Österreich, Rumänien, Irland, der Schweiz, Italien und Frankreich.
            Für weitere Informationen besuchen Sie bitte{" "}
            <a
              href={`www.nookaspace.com`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              www.nookaspace.com
            </a>
            .
          </p>
        </>
      ),
      contacts: <></>,
    },
    en: {
      cleanTitle:
        "orangery & Nooka Space startups to reimagine the coworking experience together",
      title: (
        <>
          orangery & <span className="text-brand">Nooka Space</span> startups to
          reimagine the <span className="text-brand">coworking</span> experience
          together
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Press Release November 3, 2022",
      summary:
        "Orangery & Nooka Space, two startups that bring innovation to the workspace market, join forces, and introduce  the concept of flex office on demand in Germany, a service that allows access to state of the art office spaces enhanced by technology.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Orangery & Nooka Space, two startups that bring innovation to the
            workspace market, join forces, and introduce the concept of flex
            office on demand in Germany, a service that allows access to state
            of the art office spaces enhanced by technology.
          </p>

          <p className="mb-1 text-justify">
            Dominik and Sebastian Groenen, the founders of orangery, have been
            innovating in the workspace market since 2018, with the desire to
            bring the coworking concept outside the big cities and to offer
            users exclusive features of an office space that encourages
            creativity, productivity, and urban mobility.
          </p>
          <p className="mb-1 text-justify">
            Their vision is today successfully materialised with nine locations
            in Germany and many more to come.
          </p>
          <p className="mb-3 text-justify">
            Furthermore, in cooperation with Nooka Space, the start-up that
            launched the first concept of smart and flexible proximity offices
            in the world, orangery brings a new layer of innovation by
            revolutionising the traditional office market and by developing a
            network of coworking spaces in secondary cities across Germany. The
            goal is to make working in B- and C-cities more attractive and to
            reduce the migration rate to the big cities of young graduates,
            start-ups, freelancers, but also resident companies.
          </p>

          <p className="mb-3">
            <blockquote className="italic font-semibold text-gray-800 inline">
              „orangery stands for flexibility, so we want to enable everyone to
              work from anywhere. In cooperation with Nooka Space, we are one
              step closer to making sense of this vision. We are planning to
              place brand new Nooka Cubes smart office booths at rest stops
              along highways, to allow people work on the go from fully equipped
              workstations, without having to attend, for example, an important
              meeting, from the back seat”
            </blockquote>
            , said{" "}
            <span className="font-bold">
              Sebastian Groenen, Founder of the orangery
            </span>
            .
          </p>

          <p className="mb-3">
            <span className="font-bold">
              Sandu Băbășan, Co-Founder of Nooka Space
            </span>{" "}
            added{" "}
            <blockquote className="italic font-semibold text-gray-800 inline">
              „Together with our business partner orangery, we are aiming to
              provide a smart coworking network In Germany, to improve the
              office experience of any employee or entrepreneur, and to foster
              creative and collaborative working environments. Our business
              model and the technology behind it are paving the way for the
              future of work.”
            </blockquote>
          </p>

          <p className="mb-3">
            orangery offers the possibility to rent a workspace in a flexible
            and uncomplicated way. In addition to orangery's coworking spaces
            that offer equipped workstations in private offices, open spaces and
            conference rooms, the company also brings the option to book the
            Nooka Cube office booth in Rostock and Hildesheim.
          </p>

          <p className="mb-3">
            Nooka Cube is an enjoyable and intimate place to work from, a type
            of office booth designed by Nooka Space, fully equipped with
            state-of-the-art furniture and IoT integrated, allowing users to
            have full control over its smart features. The modern design allows
            natural light to enter the space from multiple sides, while the
            architecture and the solid structure of the booth offers a high
            level of safety, shielding users from the outside weather
            conditions. Nooka Cube office booths offer a high level of privacy
            and the optimal conditions to work, by creating an island of silence
            in an open, crowded area.
          </p>

          <p className="mb-3">
            Both Nooka Space and orangery want to offer their customers a
            flexible office solution to make their work life better on different
            levels. Therefore, both companies created their own app to automate
            the booking process and make the working experience easier.
          </p>

          <p className="mb-3">
            The strategic partnership between Nooka Space and orangery has the
            goal to expand the business model across Germany and to create a
            community of users, contributing to the sustainable development of
            the smart cities.
          </p>

          <p className="mb-3">
            For further information about orangery and Nooka Space, please
            access{" "}
            <a
              href={`www.orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              www.orangery.io
            </a>{" "}
            and{" "}
            <a
              href={`www.nookaspace.com`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              www.nookaspace.com
            </a>
            .
          </p>

          <p className="text-sm mb-3">
            The <span className="font-bold">orangery</span> was established in
            2018 by Dominik & Sebastian Groenen - founders and CEO - with the
            vision to establish the coworking concept in B & C cities. Coworking
            spaces are still primarily found in metropolitan areas and that's
            exactly what the Groenen brothers wanted to change. Their goal is to
            provide a flexible, inspirational and modern workplace away from the
            big cities. But they not only want to provide a workspace by itself,
            they want to create an environment where the coworkers can work but
            also benefit from many features that integrate live aspects in the
            work environment. Some of the features include fitness areas or the
            integration of a cafe in the coworking spaces. The orangery has set
            itself the task of offering its coworkers a work experience as
            relaxed as possible and to relieve them of numerous tasks. Making
            coffee, keeping track of inventory, mail and parcel acceptance,
            cleaning, interior design - all this and many more features are
            integrated into the orangery full-service package. The orangery is
            now represented in 9 locations and many more are planned away from
            the metropolises in the DACH region in the coming years.
          </p>

          <p className="text-sm mb-3">
            <span className="font-bold">Nooka Space</span> is the start-up which
            developed the first concept of smart and flexible proximity offices
            in the world. The story of Nooka began in 2020, from the desire to
            rewrite the definition of the workspace and help build the offices
            of the future: smarter, more accessible, more flexible, more
            intimate, and more environmentally friendly. Nooka Space's business
            model offers the options for renting, booking based on hourly rates,
            buying, and sharing revenue, thus contributing to the concept of
            flexible spaces and urban mobility. The company provides the type of
            "plug and play" compact office spaces that can fit almost anywhere
            due to their specifications, whether it’s a public space, a
            residential area, a garden, or a high transited location. Currently,
            Nooka Space operates with three office models, Nooka AIR (Nooka Air
            One and Nooka Air Two), designed for indoor spaces and Nooka Cube &
            Nooka One, adapted for outdoors. Nooka Space is aiming to build a
            worldwide hybrid work community based on creativity and
            collaboration. to build a worldwide hybrid work community based on
            creativity and collaboration. Nooka Space has offices in the
            Netherlands, Germany, Austria, Romania, Ireland, Switzerland, Italy
            and France. For further information, please visit{" "}
            <a
              href={`www.nookaspace.com`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              www.nookaspace.com
            </a>
            .
          </p>
        </>
      ),
      contacts: <></>,
    },
  },
  {
    key: "orangery-coworking-jetzt-auch-in-mvs-landeshauptstadt",
    de: {
      cleanTitle: "orangery Coworking - Jetzt auch in MVS Landeshauptstadt",
      title: (
        <>
          orangery <span className="text-brand">Coworking</span> - Jetzt auch in{" "}
          <span className="text-brand">MVS Landeshauptstadt</span>
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Pressemitteilung 21. Oktober 2022",
      summary:
        "Anfang November eröffnet die orangery einen neuen Standort in Schwerin. Die neue Location befindet sich direkt in der Schweriner Innenstadt in unmittelbarer Nähe zur Fußgängerzone, dem Pfaffenteich und dem Schweriner Schloss. Mit diesem Standort eröffnen Dominik und Sebastian Groenen – Gründer der orangery – bereits die dritte orangery Location in Mecklenburg-Vorpommern nach Stralsund und Rostock.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Anfang November eröffnet die orangery einen neuen Standort in
            Schwerin. Die neue Location befindet sich direkt in der Schweriner
            Innenstadt in unmittelbarer Nähe zur Fußgängerzone, dem Pfaffenteich
            und dem Schweriner Schloss. Mit diesem Standort eröffnen Dominik und
            Sebastian Groenen – Gründer der orangery – bereits die dritte
            orangery Location in Mecklenburg-Vorpommern nach Stralsund und
            Rostock.
          </p>

          <p className="mb-3 text-justify">
            Menschen, die in Schwerin nach einem Schreibtisch oder einem Büro
            suchen, sind in der orangery genau richtig. Die Location in der
            Friedrichstraße 22 bietet auf über 200 m² genügend Platz für alles,
            was das Coworking Herz begehrt. Coworker können sowohl im geräumigen
            Open Space, aber auch im abgeschlossenen Einzelbüro kreativ und
            produktiv werden. Außerdem kann der Meetingraum für Workshops oder
            Seminare von Coworkern sowie externen NutzerInnen gebucht werden.
            Hierfür ist dieser mit allen nötigen Utensilien ausgestattet, von
            der Flipchart bis hin zum Smart-TV ist alles vorhanden, den Meetings
            steht demnach nichts im Wege. Denn die orangery bietet ihren
            Coworkern ein Full-Service-Paket. Neben den ausgestatteten
            Meetingräumen profitieren die Coworker von einem Frühstücksangebot,
            Getränken, schnellem Internet, Druckern und den bereits möblierten
            Büros. Darüber hinaus nehmen die Community ManagerInnen den
            Coworkern viele zeitaufwendige Aufgaben ab, sodass diese sich
            komplett auf ihre Arbeit fokussieren können. Zudem laden der
            Loungebereich und die Terrasse zu After Work Events und zum
            Austausch mit anderen Coworkern ein.
          </p>

          <p className="mb-3 text-justify">
            Alle, die immer schonmal wissen wollten, was hinter Coworking
            steckt, können gerne am 2. November zu dem unverbindlichen und
            kostenlosen Open Day kommen. An diesem Tag kann man sich zwischen
            9-17 Uhr die neue orangery Location anschauen und alle Fragen werden
            beantwortet. Unabhängig davon können aber auch an jedem anderen Tag
            Besichtigungen über die{" "}
            <a
              href={`www.orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              orangery.io
            </a>{" "}
            Website gebucht werden.
          </p>

          <p className="mb-3 text-justify">
            Hinter der orangery stecken die Brüder Dominik und Sebastian
            Groenen. Gemeinsam haben sie sich das Ziel gesetzt, innovative und
            zukunftsfähige Workspaces in B- & C-Städten, abseits der Metropolen,
            zu etablieren und den Arbeitsalltag ihrer Coworker so angenehm wie
            möglich zu gestalten. Für Selbstständige und große Unternehmen ist
            hier genauso Platz wie für AbsolventInnen oder Start-ups. In allen
            orangery Coworking Locations ist jeder willkommen und wird vom
            orangery Team herzlich empfangen. Durch das Aufeinandertreffen von
            verschiedenen Persönlichkeiten und Branchen bietet Coworking die
            perfekte Grundlage für Networking und interessante Gespräche.
          </p>

          <p className="mb-1">
            Interessierte können sich direkt bei{" "}
            <span className="italic">Oliver Muniz</span> melden:
          </p>
          <p className="mb-1">
            <a
              href={`mailto:o.muniz@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              o.muniz@orangery.io
            </a>
          </p>
          <p className="mb-3">
            <a
              href={`tel:015208155795`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              015208155795
            </a>
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Pressekontakt:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
    en: {
      cleanTitle:
        "orangery Coworking - now also in the capital of Mecklenburg-Vorpommern",
      title: (
        <>
          orangery <span className="text-brand">Coworking</span> - now also in
          the capital of{" "}
          <span className="text-brand">Mecklenburg-Vorpommern</span>
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Press Release October 21, 2022",
      summary:
        "At the beginning of November, the orangery opens a new location in Schwerin. The new location is located directly in Schwerin's city center in the immediate vicinity of the pedestrian zone, the Pfaffenteich and Schwerin Castle. With this location, Dominik and Sebastian Groenen - founders of orangery - are already opening the third orangery location in Mecklenburg-Vorpommern after Stralsund and Rostock.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            At the beginning of November, the orangery opens a new location in
            Schwerin. The new location is located directly in Schwerin's city
            center in the immediate vicinity of the pedestrian zone, the
            Pfaffenteich and Schwerin Castle. With this location, Dominik and
            Sebastian Groenen - founders of orangery - are already opening the
            third orangery location in Mecklenburg-Vorpommern after Stralsund
            and Rostock.
          </p>

          <p className="mb-3 text-justify">
            People looking for a desk or office in Schwerin are in the right
            place at the orangery. The location at Friedrichstraße 22 offers
            over 200 m² of space for everything a coworking heart desires.
            Coworkers can get creative and productive both in the spacious open
            space, but also in the enclosed individual office. In addition, the
            meeting room can be booked for workshops or seminars by coworkers as
            well as external users. It is equipped with all the necessary
            utensils, from flip charts to smart TVs, so nothing stands in the
            way of meetings. The orangery offers its coworkers a full-service
            package. In addition to the equipped meeting rooms, the coworkers
            benefit from a breakfast offer, drinks, fast Internet, printers and
            the already furnished offices. In addition, the community managers
            relieve the coworkers of many time-consuming tasks so that they can
            focus completely on their work. In addition, the lounge area and the
            terrace invite to after work events and to exchange with other
            coworkers.
          </p>

          <p className="mb-3 text-justify">
            Anyone who has always wanted to know what's behind coworking is
            welcome to come to the non-binding and free Open Day on November
            2nd. On this day you can have a look at the new orangery location
            between 9-17 and all questions will be answered. Separately,
            however, tours can be booked on any other day via the{" "}
            <a
              href={`www.orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              orangery.io
            </a>{" "}
            website.
          </p>

          <p className="mb-3 text-justify">
            Behind the orangery are brothers Dominik and Sebastian Groenen.
            Together, they have set themselves the goal of establishing
            innovative and sustainable workspaces in B & C cities, away from the
            metropolises, and making their coworkers' working day as pleasant as
            possible. There is room for self-employed and large companies as
            well as for graduates or start-ups. Everyone is welcome in all
            orangery coworking locations and will be warmly welcomed by the
            orangery team. By bringing together different personalities and
            industries, coworking offers the perfect basis for networking and
            interesting conversations.
          </p>

          <p className="mb-1">
            If you are interested, please contact{" "}
            <span className="italic">Oliver Muniz</span> directly:
          </p>
          <p className="mb-1">
            <a
              href={`mailto:o.muniz@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              o.muniz@orangery.io
            </a>
          </p>
          <p className="mb-3">
            <a
              href={`tel:015208155795`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              015208155795
            </a>
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Press contact:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
  },
  {
    key: "free-coworking-day-halloween",
    de: {
      cleanTitle: "Free Coworking Day: HALLOWEEN",
      title: (
        <>
          Free <span className="text-brand">Coworking</span> Day:{" "}
          <span className="text-brand">HALLOWEEN</span>
        </>
      ),
      imageSrc: image221019,
      date: "Pressemitteilung 19. Oktober 2022",
      summary:
        "Ihr wollt unbedingt raus aus dem Homeoffice, braucht mehr Platz für euer Team oder wollt ihr einfach nur mit euren KollegInnen für Süßes um die Häuser ziehen? Dann kommt doch gerne an unserem Halloween Free Coworking Day in den orangery Standort eurer Wahl.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Hallo liebe Halloween und Coworking Freunde,
          </p>

          <p className="mb-3 text-justify">
            Ihr wollt unbedingt raus aus dem Homeoffice, braucht mehr Platz für
            euer Team oder wollt ihr einfach nur mit euren KollegInnen für Süßes
            um die Häuser ziehen? Dann kommt doch gerne an unserem Halloween
            Free Coworking Day in den orangery Standort eurer Wahl*.
          </p>

          <p className="mb-3 text-justify">
            Hier könnt ihr ganz kostenlos all unsere Features testen und euch
            ein eigenes Bild von unseren Locations und dem orangery Gesamtpaket
            machen. Zwischen 9-17 Uhr erwarten euch unsere Community
            ManagerInnen und beantworten alle aufkommenden Fragen.
          </p>

          <p className="text-justify">schaurige Grüße,</p>

          <p className="mb-3 text-justify">euer orangery Team</p>

          <p className="mb-3 text-justify italic">* ausgenommen Magdeburg</p>
        </>
      ),
      contacts: <></>,
    },
    en: {
      cleanTitle: "Free Coworking Day: HALLOWEEN",
      title: (
        <>
          Free <span className="text-brand">Coworking</span> Day:{" "}
          <span className="text-brand">HALLOWEEN</span>
        </>
      ),
      imageSrc: image221019,
      date: "Press Release October 19, 2022",
      summary:
        "You want to get out of your home office, need more space for your team or just want to go trick-or-treating with your colleagues? Then feel free to come to the orangery location of your choice* on our Halloween Free Coworking Day.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Hello dear Halloween and coworking friends,
          </p>

          <p className="mb-3 text-justify">
            You want to get out of your home office, need more space for your
            team or just want to go trick-or-treating with your colleagues? Then
            feel free to come to the orangery location of your choice* on our
            Halloween Free Coworking Day.
          </p>

          <p className="mb-3 text-justify">
            Here you can test all our features for free and get your own
            impression of our locations and the orangery package. Between 9 am
            and 5 pm our community managers will be waiting for you and answer
            all your questions.
          </p>

          <p className="text-justify">scary greetings,</p>

          <p className="mb-3 text-justify">your orangery team</p>

          <p className="mb-3 text-justify italic">* except Magdeburg</p>
        </>
      ),
      contacts: <></>,
    },
  },
  {
    key: "orangery-x-nornorm-flexibilitaet-meets-design",
    de: {
      cleanTitle: "orangery x NORNORM - Flexibilität meets Design",
      title: (
        <>
          <span className="text-brand">orangery</span> x{" "}
          <span className="text-brand">NORNORM</span> - Flexibilität meets
          Design
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Pressemitteilung 29. September 2022",
      summary:
        "Die orangery befindet sich derzeit in einer Expansionsphase - mit dem Ziel, viele neue Standorte in Deutschland, Österreich und der Schweiz zu eröffnen. NORNORM wird der Partner für diese Reise sein. Die orangery bietet die flexible und unkomplizierte Anmietung von Arbeitsplätze und NORNORM wird alle neuen Coworking-Standorte mit stilvollen Büromöbeln, komfortablen Loungemöbeln und Telefonzellen ausstatten.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            Die orangery befindet sich derzeit in einer Expansionsphase - mit
            dem Ziel, viele neue Standorte in Deutschland, Österreich und der
            Schweiz zu eröffnen. NORNORM wird der Partner für diese Reise sein.
            Die orangery bietet die flexible und unkomplizierte Anmietung von
            Arbeitsplätze und NORNORM wird alle neuen Coworking-Standorte mit
            stilvollen Büromöbeln, komfortablen Loungemöbeln und Telefonzellen
            ausstatten.
          </p>

          <p className="mb-3 text-justify">
            <blockquote className="italic font-semibold text-gray-800 inline">
              „Die orangery legt großen Wert auf eine stilvolle Einrichtung und
              Flexibilität. NORNORM ist hier der optimale Partner, da sie mit
              nordischen Designmöbeln eine voll flexible, nachhaltige und
              zirkuläre Lösung anbieten”
            </blockquote>
            , sagt Dominik Groenen – CEO der orangery Coworking GmbH.
          </p>

          <p className="mb-3 text-justify">
            NORNORM bietet Unternehmen attraktive Arbeitsplatzlösungen auf der
            Basis eines Abomodells an. Es erfordert weder Investitionen noch
            langfristige Verpflichtungen und bietet die Möglichkeit, die Anzahl
            der Möbel zu erhöhen oder zu verringern, wenn das Unternehmen wächst
            oder schrumpft. Das Konzept basiert auf dem Kreislaufprinzip, bei
            dem jedes Möbelstück von einem Arbeitsplatz zum nächsten
            weitergereicht und jedes Produkt sorgfältig gewartet und überholt
            wird, um seine Lebensdauer zu verlängern. Das ist gut für die
            Wirtschaft, die Menschen und den Planeten. NORNORM ist nicht nur ein
            Konzept, ein Unternehmen oder eine Dienstleistung - es ist eine
            neue, kreislauforientierte Art, Geschäfte zu machen. Für die
            orangery ist einer der wichtigsten Punkte für die Zukunft, dass wir
            die Langlebigkeit aller Objekte in unseren Räumen garantieren.
            NORNORM betreut bereits orangery-Standorte in Rostock, Schwerin und
            Lemgo.
          </p>

          <p className="mb-3 text-justify">
            <blockquote className="italic font-semibold text-gray-800 inline">
              „NORNORM freut sich über die Partnerschaft mit der orangery und
              den gemeinsamen Wachstumsweg, der vor uns liegt. Nach der
              Expansion in die wichtigsten Wirtschaftsmetropolen Deutschlands
              freuen wir uns nun darauf, gemeinsam mit der orangery die Zukunft
              des Coworkings in den Städten abseits der Metropolen im ganzen
              Land zu gestalten”
            </blockquote>
            , sagt Nikolas Hartlieb von NORNORM.
          </p>

          <p className="mb-3 text-justify">
            Die orangery will die traditionelle Bürovermietung revolutionieren
            und baut Coworking Spaces in Secondary Cities auf. Ziel ist es, das
            Arbeiten in Städten mit bis zu 300.000 Einwohnern attraktiver zu
            gestalten, um der Abwanderungsrate von jungen Absolvierenden,
            Startups, Freiberuflern, aber auch ansässigen Unternehmen in die
            Großstädte entgegenzuwirken. Die orangery bietet die Möglichkeit,
            flexibel und unkompliziert einen Arbeitsplatz zu mieten. Vom
            Arbeiten im Einzelbüro, über Schreibtische in Großraumbüros und
            Besprechungsräume für Konferenzen mit Geschäftspartnern ist für
            jeden etwas dabei. Zusätzlich können die gemütlichen Lounge-Bereiche
            produktiv oder zum Networking mit anderen Coworkern aus
            verschiedenen Branchen genutzt werden. Mit ihren Spaces verfolgen
            die Gründer und CEOs der orangery - Dominik und Sebastian Groenen -
            seit 2018 das Ziel, Coworking abseits der Metropolen zu etablieren
            und ihren Coworkern den Arbeitsalltag mit exklusiven Features so
            angenehm wie möglich zu gestalten. Mit diesem konsequenten Ansatz in
            den Secondary Cities ist die orangery die Nummer 1 auf dem Markt und
            der einzige flächendeckende Anbieter in ganz Deutschland.
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Pressekontakt:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
    en: {
      cleanTitle: "orangery x NORNORM - Flexibility meets design",
      title: (
        <>
          <span className="text-brand">orangery</span> x{" "}
          <span className="text-brand">NORNORM</span> - Flexibility meets design
        </>
      ),
      imageSrc: firstNewsImage,
      date: "Press Release September 29, 2022",
      summary:
        "The orangery is currently in an expansion phase - with the ambition to expand to many new locations in Germany, Austria and Switzerland. NORNORM will be the partner for this journey. Orangery offers workplaces in a flexible and uncomplicated way and NORNORM will equip all new coworking locations with stylish office furniture, comfortable lounge furniture, and phone booths.",
      body: (
        <>
          <p className="mb-3 text-justify font-semibold">
            The orangery is currently in an expansion phase - with the ambition
            to expand to many new locations in Germany, Austria and Switzerland.
            NORNORM will be the partner for this journey. Orangery offers
            workplaces in a flexible and uncomplicated way and NORNORM will
            equip all new coworking locations with stylish office furniture,
            comfortable lounge furniture, and phone booths.
          </p>

          <p className="mb-3 text-justify">
            <blockquote className="italic font-semibold text-gray-800 inline">
              „The orangery attaches great importance to stylish furnishings and
              flexibility. NORNORM is the optimal partner since they offer a
              fully flexible, sustainable, and circular solution with nordic
              design furniture.”
            </blockquote>{" "}
            says Dominik Groenen at the orangery.
          </p>

          <p className="mb-3 text-justify">
            NORNORM provides businesses with attractive workplace furniture
            solutions based on a subscription model. Requiring neither
            investment nor long-term commitment, it offers flexibility to scale
            up or down as the company grows or downsizes. The concept is built
            on circularity, where every piece of furniture is kept in a loop
            from one workspace to the next, and every product is carefully
            maintained and refurbished to extend its lifespan. Good for
            business, people, and the planet. NORNORM is not just a concept, a
            company, or a service — it is a new and circular way of doing
            business. For the orangery, one of the most important points for the
            future is that we guarantee the longevity of all objects within our
            spaces. NORNORM already serves orangery locations in Rostock,
            Schwerin, and Lemgo.
          </p>

          <p className="mb-3 text-justify">
            <blockquote className="italic font-semibold text-gray-800 inline">
              „NORNORM is thrilled about the partnership with the orangery and
              the joint growth journey ahead. After expanding to the main
              business cities in Germany, we are now much looking forward to
              joining the orangery in building the future of co-working in
              2nd-tier cities throughout the country”
            </blockquote>{" "}
            says Nikolas Hartlieb at NORNORM.
          </p>

          <p className="mb-3 text-justify">
            The orangery wants to revolutionize traditional office rental and is
            building coworking spaces in secondary cities. The goal is to make
            working in cities with up to 300,000 inhabitants more attractive to
            counteract the migration rate of young graduates, startups,
            freelancers, but also resident companies to big cities. The orangery
            offers the possibility to rent a workplace in a flexible and
            uncomplicated way. From working in a private office, desks in open
            spaces, and meeting rooms, to conferences with business partners,
            there is something for everyone. Additionally, the lounge area can
            be used productively or for networking with other coworkers from
            different industries in a cozy atmosphere. With their Spaces, the
            founders and CEOs of orangery - Dominik and Sebastian Groenen - have
            been pursuing the goal since 2018 of establishing coworking away
            from the metropolises and making their coworkers' everyday working
            life as pleasant as possible with exclusive features. With this
            consistently conceived approach in second tier cities, the orangery
            is Number 1 in the market and the only nationwide provider in all of
            Germany.
          </p>
        </>
      ),
      contacts: (
        <>
          <p className="mb-1 mt-12">Press contact:</p>
          <p className="mb-1">orangery coworking GmbH</p>
          <p className="mb-1">Svenja Schutta</p>
          <p className="mb-1">
            <a
              href={`mailto:s.schutta@orangery.io`}
              className="text-indigo-500 hover:text-indigo-900"
            >
              s.schutta@orangery.io
            </a>
          </p>
        </>
      ),
    },
  },
  {
    key: "orangery-wir-lieben-hildesheim",
    cleanTitle: "orangery – Wir lieben Hildesheim!",
    title: (
      <>
        <span className="text-brand">orangery</span> – Wir lieben{" "}
        <span className="text-brand">Hildesheim!</span>
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 29. September 2022",
    summary:
      "Die Visionen zur orangery sind 2018 in Hildesheim entstanden und wurden direkt mit der ersten Coworking Location umgesetzt.  CEOs und Gründer der orangery Coworking GmbH – Dominik und Sebastian Groenen – verfolgen das Ziel, das New Work Konzept in Hildesheim und auch in anderen Städten mit bis zu 300.000 Einwohnern umzusetzen.",
    body: (
      <>
        <p className="mb-3 text-justify font-semibold">
          Die Visionen zur orangery sind 2018 in Hildesheim entstanden und
          wurden direkt mit der ersten Coworking Location umgesetzt. CEOs und
          Gründer der orangery Coworking GmbH – Dominik und Sebastian Groenen –
          verfolgen das Ziel, das New Work Konzept in Hildesheim und auch in
          anderen Städten mit bis zu 300.000 Einwohnern umzusetzen. Und das ist
          ihnen gelungen, schon kurz nach der Eröffnung des ersten Coworking
          Spaces folgten zwei weitere Standorte in Hildesheim und in den letzten
          4 Jahren kamen 9 Standorte in anderen Teilen Deutschlands hinzu.
          Aufgrund der bereits drei erfolgreichen Standorte in der Heimatstadt
          der orangery, soll nun auch der vierte Coworking Space in Hildesheim
          folgen. Dieser wird voraussichtlich im Mai 2023 an der Hildesheimer
          Münchewiese eröffnet.
        </p>

        <p className="mb-3 text-justify">
          In der neuen Hildesheimer Location kommt niemand zu kurz. Auf einer
          gigantischen Fläche von fast 1500 m² gibt es alles, was das Coworking
          Herz begehrt. Von einer großen Holzterrasse, über die erstklassige
          Ausstattung, bis hin zur individuellen Beratung kann der neue Standort
          mit vielen Features punkten. Die Coworking Spaces der orangery sind
          alle so konzipiert, dass den Coworkern viele der lästigen alltäglichen
          Aufgaben abgenommen werden. So können sich diese voll und ganz auf
          ihre Arbeit konzentrieren und das ganze in einer stilvoll
          eingerichteten Umgebung und einer möglichst inspirierenden
          Arbeitsatmosphäre. Die Coworker können hier sowohl in einem
          abgeschlossenen Einzelbüro, aber auch an einem Tisch im Open Space
          arbeiten. Zudem können die Workshop- und Meetingräume von Coworkern
          und auch Externen für Veranstaltungen und Seminare gebucht werden.
          Neben den orangery typischen Büros und Arbeitsplätzen bieten wir an
          der Münchewiese erstmalig die Möglichkeit, Utensilien in sogenannten
          “Storage Cubes” unterzubringen. Doch auch unabhängig von der Arbeit
          kommen die Coworker am neuen orangery Standort auf ihre Kosten: Die
          Indoor und Outdoor Fitness-Area bieten den optimalen Ausgleich zum
          stressigen Arbeitsalltag. Ebenso lädt die Holzterrasse zum Entspannen
          und zum Austausch mit anderen Coworkern ein. Wann hat man schonmal die
          Möglichkeit, an einem Ort zu arbeiten, an dem so viele verschiedene
          Persönlichkeiten aus unterschiedlichen Branchen zusammenkommen?
        </p>

        <p className="mb-3 text-justify">
          Unternehmergeist in einem kreativen Raum: Wir wollen das
          orangery-Erlebnis niemandem vorenthalten und laden jeden ein, einen
          Zugang zum Coworking zu finden, sich inspirieren zu lassen und
          spannende Bekanntschaften zu machen.
        </p>

        <p className="mb-3 text-justify">
          Der neue Coworking Space befindet sich zentral in der
          Eduard-Ahlborn-Straße und somit mit hervorragender Anbindung an die
          Autobahn zur Hauptzufahrt in alle Richtungen der A7 in Richtung
          Hannover, Hamburg und Kassel. Ebenfalls kann die Hildesheimer
          Innenstadt mit öffentlichen Verkehrsmitteln in nur wenigen Minuten
          erreicht werden. In unmittelbarer Nähe gibt es außerdem einen
          Supermarkt, einen Bäcker, eine Tankstelle und einen Burger King.
        </p>
      </>
    ),
    // contacts: <>
    //   <p className="mb-1 mt-12">
    //     Pressekontakt:
    //   </p>
    //   <p className="mb-1">
    //     orangery coworking GmbH
    //   </p>
    //   <p className="mb-1">
    //     Svenja Schutta
    //   </p>
    //   <p className="mb-1">
    //     <a href={`mailto:s.schutta@orangery.io`} className="text-indigo-500 hover:text-indigo-900">s.schutta@orangery.io</a>
    //   </p>
    // </>
  },
  {
    key: "coworking-jetzt-direkt-auf-dem-campus-in-lemgo",
    cleanTitle: "Coworking jetzt direkt auf dem Campus in Lemgo",
    title: (
      <>
        <span className="text-brand">Coworking</span> jetzt direkt auf dem
        Campus in <span className="text-brand">Lemgo</span>
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 01. September 2022",
    summary:
      "Lemgo, 01.09.2022  – Die orangery Coworking GmbH eröffnet im Oktober eine neue Location in Lemgo. Hier entsteht im Centrum Industrial IT (CIIT) und damit in unmittelbarer Nähe zur  Technischen Hochschule Ostwestfalen-Lippe ein neuer Coworking Space.",
    body: (
      <>
        <p className="mb-3 text-justify font-semibold">
          Lemgo, 01.09.2022 – Die orangery Coworking GmbH eröffnet im Oktober
          eine neue Location in Lemgo. Hier entsteht im Centrum Industrial IT
          (CIIT) und damit in unmittelbarer Nähe zur Technischen Hochschule
          Ostwestfalen-Lippe ein neuer Coworking Space.
        </p>

        <p className="mb-3 text-justify">
          Die neue orangery Location befindet sich direkt in dem Gebäude des
          Centrum Industrial IT an der Campusallee in Lemgo. Auf einer Fläche
          von fast 400 m² entstehen zahlreiche Büros, zusätzlich bietet das
          Auditorium des Gebäudes mit 200 m² genug Platz für die Durchführung
          von Veranstaltungen oder Workshops. Hier können Studierende,
          AbsolventInnen, potentielle GründerInnen, aber auch gestandene
          Unternehmen von den Features der orangery profitieren. Von der
          Einrichtung und Büroausstattung, bis hin zu Frühstück, Kaffee und
          Infused-Water ist für alles gesorgt. Sodass die Coworker sich stets
          auf ihre Arbeit konzentrieren und die inspirierende Arbeitsatmosphäre
          genießen können.
        </p>

        <p className="mb-3 text-justify">
          Die orangery bietet seit 2018 flexible Bürolösungen für alle, die dem
          Homeoffice entfliehen möchten oder mehr Platz für ihr Team benötigen.
          Vom Arbeiten in abschließbaren Einzelbüros, bis hin zu einem flexiblen
          Arbeitsplatz im Open Space, aber auch Meetings mit
          GeschäftspartnerInnen ist hier alles möglich. Zusätzlich können die
          Coworker aus den verschiedensten Bereichen ständig in den Austausch
          untereinander treten, hierfür eignen sich beispielsweise die
          verschiedenen Lounge Bereiche. Somit verfolgen Dominik und Sebastian
          Groenen – Founder und CEOs der orangery Coworking GmbH – das Ziel,
          Coworking mit zahlreichen Add-Ons außerhalb der Metropolen attraktiv
          zu etablieren.
        </p>

        <p className="mb-3 text-justify">
          An der Campusallee ist jeder eingeladen, einen Zugang zum Coworking zu
          finden, sich inspirieren zu lassen und spannende Bekanntschaften zu
          machen. Oder eben einfach nur eine gute Tasse Kaffee mit unserer
          Community zu genießen. Wenn dein Interesse geweckt wurde und du auf
          der Suche nach einem Büro oder dem perfekten Ort für deinen nächsten
          Workshop bist, dann wende dich gerne mit Fragen oder Terminwünschen an
          Oliver Muniz:
        </p>

        <p className="text-justify">
          Telefon: <a href={`tel:015208155795`}>015208155795</a>
        </p>

        <p className="mb-3 text-justify">
          Mail:{" "}
          <a
            href={`mailto:o.muniz@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            o.muniz@orangery.io
          </a>
        </p>
      </>
    ),
    contacts: (
      <>
        <p className="mb-1 mt-12">Pressekontakt:</p>
        <p className="mb-1">orangery coworking GmbH</p>
        <p className="mb-1">Svenja Schutta</p>
        <p className="mb-1">
          <a
            href={`mailto:s.schutta@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            s.schutta@orangery.io
          </a>
        </p>
      </>
    ),
  },
  {
    key: "solingen-wir-sind-ready",
    cleanTitle: "Solingen - wir sind ready!",
    title: (
      <>
        <span className="text-brand">Solingen</span> -
        <br />
        wir sind ready!
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 08. August 2022",
    summary:
      "Am 08.08.2022 eröffnet die orangery Coworking GmbH ihren sechsten Coworking Space. Unsere neue Location entsteht in dem ehemaligen Gebäude der solidarischen Konsumgenossenschaft an der Prinzenstraße in Solingen/Ohligs. Damit verfolgen Dominik und Sebastian Groenen – Gründer und CEOs der orangery – weiterhin das Ziel, Coworking Spaces außerhalb der Großstädte aufzubauen.",
    body: (
      <>
        <p className="mb-3 text-justify font-semibold">
          Am 08.08.2022 eröffnet die orangery Coworking GmbH ihren sechsten
          Coworking Space. Unsere neue Location entsteht in dem ehemaligen
          Gebäude der solidarischen Konsumgenossenschaft an der Prinzenstraße in
          Solingen/Ohligs. Damit verfolgen Dominik und Sebastian Groenen –
          Gründer und CEOs der orangery – weiterhin das Ziel, Coworking Spaces
          außerhalb der Großstädte aufzubauen.
        </p>

        <p className="text-justify">
          Der Solinger Standort ist mit 3000m² unsere bisher größte Coworking
          Location. Neben den üblichen Einzelbüros, Flex Desks und Meetingräumen
          werden auch weitere Angebote wie Fitness, Sauna und Dachterrasse in
          dem Gebäude an der Prinzenstraße entstehen. Wir versuchen mit all
          unseren Standorten ein inspirierendes und stilvolles Arbeitsumfeld zu
          schaffen, dies soll nun auch in Solingen fortgeführt werden. Zudem
          ermöglicht unser Rundumservice den Coworkern einen entspannten
          Arbeitsalltag. Von Getränken, Wasser, Müsli und Obst, über unsere
          designvollen Möbeln, bis hin zum Drucker und Lounge- Areas ist alles
          vorhanden. Weitere Details sind auf unseren Social Media Kanälen zu
          finden (Instagram: orangery_coworking). Bei weiteren Fragen oder
          Terminwünschen könnt ihr euch gerne an Oliver Muniz wenden:
        </p>

        <p className="text-justify">
          Telefon: <a href={`tel:015208155795`}>015208155795</a>
        </p>

        <p className="mb-3 text-justify">
          Mail:{" "}
          <a
            href={`mailto:o.muniz@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            o.muniz@orangery.io
          </a>
        </p>

        <p className="mb-3 text-justify">
          Die orangery Coworking GmbH will die klassische Bürovermietung
          revolutionieren und baut Coworking Spaces in Secondary Cities auf.
          Ziel ist es, das Arbeiten in Städten mit bis zu 300.000 Einwohnern
          attraktiver zu gestalten, um der Abwanderungsquote von jungen
          Absolvierenden, StartUps, Freelancern, aber auch ansässigen
          Unternehmen in Großstädte entgegenzuwirken. Bei dieser Mission wird
          die orangery Coworking GmbH von Mirko Novakovic als Investor
          unterstützt. Novaković und New Forge sind zudem selber als Coworker an
          der Prinzenstraße vertreten. Die orangery bietet die Möglichkeit,
          flexibel und unkompliziert einen Arbeitsplatz anzumieten. Vom Arbeiten
          in einem privaten Büro, Schreibtischen im Open Space, Meetingräumen,
          bis hin zu Konferenzen mit Geschäftspartnern, ist für jeden das
          Passende dabei. Zusätzlich kann der Loungebereich in gemütlicher
          Atmosphäre produktiv oder zum Vernetzen mit anderen Coworkern aus
          verschiedenen Branchen genutzt werden.
        </p>
      </>
    ),
    contacts: (
      <>
        <p className="mb-1 mt-12">Pressekontakt:</p>
        <p className="mb-1">orangery coworking GmbH</p>
        <p className="mb-1">Svenja Schutta</p>
        <p className="mb-1">
          <a
            href={`mailto:s.schutta@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            s.schutta@orangery.io
          </a>
        </p>
      </>
    ),
  },
  {
    key: "proptech-unternehmen-orangery-erhält-weitere-wachstumsfinanzierung-und-plant-zahlreiche-neue-standorte",
    cleanTitle:
      "PropTech-Unternehmen orangery erhält weitere Wachstumsfinanzierung und plant zahlreiche neue Standorte",
    title: (
      <>
        <span className="text-brand">PropTech-Unternehmen orangery</span> erhält
        weitere
        <br />
        Wachstumsfinanzierung und plant{" "}
        <span className="text-brand">zahlreiche neue Standorte</span>
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 20. Juli 2022",
    summary:
      "Das Prop Tech-Unternehmen orangery hat eine weitere Wachstumsfinanzierung in Millionenhöhe erhalten. Erneut investiert New Forge Gründer Mirko Novakovic in die orangery Coworking GmbH. Außerdem konnten Dominik und Sebastian Groenen – Gründer der orangery – mit Jan Meiswinkel einen weiteren Investor über die Advancis Holding GmbH aus der Nähe von Frankfurt gewinnen. Zudem wird die orangery zukünftig auch von Thomas Fürst als Investor unterstützt. Fürst hat 2012 das Finanztechnologie-Unternehmen WebID Solutions GmbH gegründet und kommt genau wie Novakovic aus Solingen, wo nächsten Monat ein weiterer Standort der orangery Coworking GmbH auf über 3000m² eröffnet wird.",
    body: (
      <>
        <p className="mb-3 text-justify">
          Das Prop Tech-Unternehmen orangery hat eine weitere
          Wachstumsfinanzierung in Millionenhöhe erhalten. Erneut investiert New
          Forge Gründer Mirko Novakovic in die orangery Coworking GmbH. Außerdem
          konnten Dominik und Sebastian Groenen – Gründer der orangery – mit Jan
          Meiswinkel einen weiteren Investor über die Advancis Holding GmbH aus
          der Nähe von Frankfurt gewinnen. Zudem wird die orangery zukünftig
          auch von Thomas Fürst als Investor unterstützt. Fürst hat 2012 das
          Finanztechnologie-Unternehmen WebID Solutions GmbH gegründet und kommt
          genau wie Novakovic aus Solingen, wo nächsten Monat ein weiterer
          Standort der orangery Coworking GmbH auf über 3000m² eröffnet wird.
        </p>

        <p className="mb-3 text-justify">
          Die orangery verfolgt seit 2018 das Ziel, designvolle Office Spaces in
          Second Tier Cities mit bis zu 300.000 Einwohnern zu etablieren und für
          ihre NutzerInnen so flexibel wie möglich umzusetzen. Aktuell betreibt
          die orangery Coworking Spaces in Hildesheim, Stralsund und Rostock. In
          den kommenden Wochen werden Locations in Solingen, Magdeburg, Lemgo
          und Schwerin eröffnet. In den nächsten 36 Monaten soll in der DACH
          Region eine höhere zweistellige Zahl von neuen Locations entstehen wie
          z.B. in Langen bei Frankfurt. Die neuen Standorte sollen mit der neuen
          Finanzierung durch Novakovic, Fürst und Meiswinkel realisiert werden.
          Letzterer fokussiert sich auf die geplante Location in Langen, hier
          entsteht auf 30.000m² ein urbaner Campus, auf dem Wohnen, Arbeiten und
          Freizeit kombiniert werden. Das Mega-Projekt in Langen wird ebenfalls
          komplett durch Jan Meiswinkel und die Advancis Immobilien GmbH
          entwickelt. Ab August 2023 wird hier auf über 3000m² ein neuer
          orangery Coworking Space in Langen entstehen mit Offices,
          Meetingräumen, Workshopräumen, Fitnessbereich, Coffeeshop, Foodcourt
          und weiteren Highlights.
        </p>

        <p className="mb-3 text-justify">
          Weiterhin soll in das orangery Tech-Team in Lübeck und Istanbul
          investiert werden. Aktuell umfasst das Team 14 MitarbeiterInnen im
          Bereich der Softwareentwicklung, zukünftig soll es mehr als 20
          EntwicklerInnen geben. Diese arbeiten an der Automatisierung von
          Prozessen und entwickeln die Coworking Erfahrung der NutzerInnen
          stetig weiter.
        </p>

        <p className="mb-3 text-justify">
          Neben der Expansion und dem Fokus auf neue Locations arbeitet die
          orangery daran, weitere Services in das Konzept zu integrieren. Schon
          jetzt werden Food und Beverages durch das roots ́n ́seeds in die Office
          Spaces integriert. Künftig sollen auch Bereiche wie Fitness, Wellness,
          Barber, CoLiving, Concierge und Laundry Services sowie Kinderbetreuung
          Platz in den orangery Locations finden. Den Gründern – Dominik und
          Sebastian Groenen – geht es schon lange nicht mehr ausschließlich um
          die Etablierung von reinen Office Spaces. Sie wollen ihren Coworkern
          eine inspirierende und angenehme Umgebung schaffen, welche mit einer
          Vielzahl von Services und AddOns über das Arbeiten hinausgeht und
          dieses nochmals attraktiver macht.
        </p>
      </>
    ),
    contacts: (
      <>
        <p className="mb-1 mt-12">Pressekontakt:</p>
        <p className="mb-1">orangery coworking GmbH</p>
        <p className="mb-1">Svenja Schutta</p>
        <p className="mb-1">
          <a
            href={`mailto:s.schutta@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            s.schutta@orangery.io
          </a>
        </p>
      </>
    ),
  },
  {
    key: "orangery-in-hameln-geht-orangery+-app-kommt",
    cleanTitle: "orangery in Hameln geht – orangery+ App kommt",
    title: (
      <>
        orangery in <span className="text-brand">Hameln</span> geht –
        <br />
        <span className="text-brand">orangery+ App</span> kommt
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 30. Juni 2022",
    summary:
      "Mit der Gründung der orangery Hameln 2019 wurde in Hameln der erste Meilenstein zur Förderung von flexiblem Arbeiten gesetzt. Die Coworking Location in der Wittekindstraße erstreckte sich über zwei Etagen und 600m². Mit dem Standort in Hameln wollte die orangery Coworking GmbH, die Abwanderung ansässiger Unternehmen, Studierender, aber auch Selbstständiger verhindern und diesen Personen ein inspirierendes und flexibles Arbeitsumfeld bieten.",
    body: (
      <>
        <p className="mb-3 text-justify">
          Mit der Gründung der orangery Hameln 2019 wurde in Hameln der erste
          Meilenstein zur Förderung von flexiblem Arbeiten gesetzt. Die
          Coworking Location in der Wittekindstraße erstreckte sich über zwei
          Etagen und 600m². Mit dem Standort in Hameln wollte die orangery
          Coworking GmbH, die Abwanderung ansässiger Unternehmen, Studierender,
          aber auch Selbstständiger verhindern und diesen Personen ein
          inspirierendes und flexibles Arbeitsumfeld bieten.
        </p>

        <p className="mb-3 text-justify">
          Die orangery bietet ihren Coworkern ein all-inklusive Angebot, vom
          Frühstück, über die Getränke, bis hin zur Büroausstattung und dem
          Internet ist für alles gesorgt. Flexibilität steht an allen Standorten
          der orangery an erster Stelle, es gibt keine Kündigungsfristen oder
          Vertragslaufzeiten. Die Coworker können sich somit auf ihre Arbeit
          fokussieren und bei Bedarf in den Austausch mit anderen Coworkern
          treten. Das offene und klassische Konzept des Coworkings wurde in
          Hameln gut aufgenommen und fand viele Anhänger. Aufgrund von massiven
          Baumängeln an und innerhalb der Immobilie sind wir leider gezwungen
          gewesen, den langfristig abgeschlossenen Mietvertrag vorzeitig zu
          kündigen und den Standort aufzugeben.
        </p>

        <p className="mb-3 text-justify">
          Dennoch verschwindet die orangery nicht vollständig aus der
          Rattenfängerstadt. Zukünftig wird sie sich auf die Etablierung der
          orangery+ App konzentrieren. Die App ermöglicht produktives Arbeiten
          von überall aus. Somit können Nachmittagsmeetings mit einem Besuch im
          Cafe verbunden werden. Die User können über die App eine orangery+
          Location in der Nähe finden und sich direkt flexibel einbuchen. Auch
          Vorab-Bestellungen von Getränken und Speisen sind bei einem Großteil
          unser orangery+ Partner möglich. Mit der orangery+ App können die
          Nutzer in unmittelbarer Nähe einen Arbeitsplatz finden. Bei den
          orangery-Partnern kann es sich um Fitnessstudios, Cafes, Restaurants,
          aber auch Pop-Up Stores handeln. Wir setzen der Kreativität bei der
          Arbeitsplatzwahl keine Grenzen. Unsere Vision ist es, das flexible
          Arbeiten auf das nächste Level zu bringen.
        </p>
      </>
    ),
    contacts: (
      <>
        <p className="mb-1 mt-12">
          Für weitere Presseinformationen, Interviewanfragen und Bildmaterial:
        </p>
        <p className="mb-1">Johanna Knuhr</p>
        <p className="mb-1">Marketing Management</p>
        <p className="mb-1">orangery coworking GmbH</p>
        <p className="mb-1">
          <a
            href={`mailto:j.knuhr@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            j.knuhr@orangery.io
          </a>
        </p>
      </>
    ),
  },
  {
    key: "orangery-holt-ehemaligen-spaces-gruender-in-das-team-martijn-roordink-verstärkt-als-board-advisor-das-team-um-die-gruender-dominik-und-sebastian-groenen",
    cleanTitle:
      "orangery holt ehemaligen Spaces- Gründer in das Team - Martijn Roordink verstärkt als Board Advisor das Team um die Gründer Dominik und Sebastian Groenen",
    title: (
      <>
        orangery holt ehemaligen{" "}
        <span className="text-brand">Spaces- Gründer</span> in das Team -
        <br />
        <span className="text-brand">Martijn Roordink</span> verstärkt als Board
        Advisor das Team
        <br /> um die Gründer Dominik und Sebastian Groenen
      </>
    ),
    imageSrc: firstNewsImage,
    date: "Pressemitteilung 01. Juni 2022",
    summary:
      "Das niedersächsische Coworking-Unternehmen von flexiblen und designvollen Workspaces holt sich mit Martijn Roordink einen fundierten Experten der internationalen Coworking-Branche ins Team. Der ehemalige Spaces Gründer & CEO bringt wertvolle Branchenexpertise aus rund 20 Jahren in unterschiedlichen Spitzenpositionen, Unternehmensbereichen und Regionen mit.",
    body: (
      <>
        <p className="mb-3 text-justify">
          Das niedersächsische Coworking-Unternehmen von flexiblen und
          designvollen Workspaces holt sich mit Martijn Roordink einen
          fundierten Experten der internationalen Coworking-Branche ins Team.
          Der ehemalige Spaces Gründer & CEO bringt wertvolle Branchenexpertise
          aus rund 20 Jahren in unterschiedlichen Spitzenpositionen,
          Unternehmensbereichen und Regionen mit.
        </p>

        <p className="mb-3 text-justify">
          In den vergangenen 15 Jahren hat Martijn Roordink mit Spaces einen der
          weltweit größten Anbieter von flexiblen Office Lösungen aufgebaut.
          Martijn Roordink hat mit seinem Team über 250 Standorte weltweit
          eröffnet und hat federführend die Expansion von Spaces vorangetrieben.
          Spaces verfügt heute über mehr als 450 Locations weltweit. Wir sind
          sehr stolz darauf, was wir mit dem gesamten Team seit 2018 mit unserer
          Idee von Coworking Spaces in Secondary Cities abseits der Metropolen
          erreicht haben. Aber noch besser wissen wir, dass wir noch über
          enormes Potenzial im weltweiten Immobilienmarkt verfügen. Daher freuen
          wir uns sehr, dass wir Martijn als Advisor für unser Team gewinnen
          konnten. Mit seinen herausragenden Branchen-Insights wird Martijn uns
          strategisch helfen, die orangery in Zukunft noch erfolgreicher am
          Markt zu positionieren und unsere Wachstumsstrategie noch schneller
          voranzutreiben“, erklärt orangery CEO und Gründer Dominik Groenen.
          „Ich freue mich sehr darauf, ein agiles und hochmotiviertes Startup
          mit viel Potenzial strategisch zu unterstützen, um gemeinsam mit dem
          Team weitere nachhaltige Investments für VCs zu sichern”, so Martijn
          Roordink. Der Shared Office Bereich zählt gerade durch die Pandemie zu
          den absoluten Wachstumsmärkten in der Real Estate Branche, wobei die B
          und C-Städte und die globalen Potenziale in diesen Regionen noch
          unausgeschöpft sind.
        </p>

        <p className="mb-3 text-justify">
          Martijn Roordink ist 2020 bei Spaces ausgeschieden und beginnt ab dem
          01. Juni 2022 die Tätigkeit im Team der orangery. Der Fokus von
          Martijn wird die strategische Unterstützung in den Bereichen
          Expansion, Internationalisierung und Fundraising sein. Nachdem die
          orangery im vergangenen Jahr erfolgreich Ihre Seed-Finanzierungsrunde
          mit New Forge und Mirko Novakovic abschließen konnte, kommt nun mit
          der Berufung von Martijn Roordink ein namhafter und kompetenter
          Experte aus der Branche in das aufstrebende Startup-Unternehmen dazu.
        </p>

        <p className="mb-3 text-justify">
          Die orangery wurde 2018 von den Brüdern Dominik und Sebastian Groenen
          gegründet und ist auf flexible Büroflächen abseits der Metropolen wie
          Hildesheim, Stralsund, Rostock, Solingen und Magdeburg spezialisiert.
          Dabei bietet das Unternehmen designvolle Räumlichkeiten mit einem
          Full-Service-Angebot an, welche u.a. von voll ausgestatteter digitaler
          Infrastruktur, täglichem Frühstück bis hin zur exklusiven orangery
          Business-Community reichen. Im Laufe der nächsten 24 Monate möchte die
          orangery auf 50 Standorte wachsen und hat damit Martijn Roordink als
          Strategen zum richtigen Zeitpunkt für das Team gewinnen können.
        </p>
      </>
    ),
    contacts: (
      <>
        <p className="mb-1 mt-12">
          Für weitere Presseinformationen, Interviewanfragen und Bildmaterial:
        </p>
        <p className="mb-1">Johanna Knuhr</p>
        <p className="mb-1">Marketing Management</p>
        <p className="mb-1">orangery coworking GmbH</p>
        <p className="mb-1">
          <a
            href={`mailto:j.knuhr@orangery.io`}
            className="text-indigo-500 hover:text-indigo-900"
          >
            j.knuhr@orangery.io
          </a>
        </p>
      </>
    ),
  },
];

export default newsList;
